import React from 'react'
import { Link } from 'react-router-dom'

export default function ReachUsBtn() {
    return (
        <div class="elementor-element elementor-element-fb49be5 shadepro-sticky-no elementor-widget elementor-widget-shadepro-btn" >
            <div class="elementor-widget-container">
                <div class="shadepro-btn-wrapper enable-icon-box-no">
                    <Link class="shadepro-btn btn-type-boxed elementor-animation-float" to="/contact">
                        Reach Us
                    </Link>
                </div>
            </div>
        </div>
    )
}
