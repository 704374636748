import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { mainMenuRoutes, privateRoutes, routes } from './routes'
import Layout from '../layout/Layout'
// import { useSelector } from 'react-redux';

export default function RouteApp() {
    // const { currentUser, isLogin } = useSelector((state) => state.auth);
    const isLogin = true;
    const allRoutes = [...mainMenuRoutes, ...routes]

    return (
        <Routes>
            {privateRoutes.map((route, i) => {
                if (!isLogin) {
                    return <Route exact
                        path={`*`}
                        element={<Navigate to={`/`} />}
                    />
                }
                return <Route element={<Layout />} key={i} >
                    <Route path={route.path} element={route.component} />
                </Route>
            })}

            {allRoutes.map((route, i) => {
                return <Route element={<Layout />} key={i}>
                    <Route path={route.path} element={route.component} />
                </Route>
            })}
        </Routes>
    )

}
