import React, { useEffect } from 'react'

export default function Broucher() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div class="shadepro-page-builder-wrapper">

            <div data-elementor-type="wp-page" data-elementor-id="15823" class="elementor elementor-15823">
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-f1457f5 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                    data-id="f1457f5" data-element_type="section"
                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                    <div class="elementor-container elementor-column-gap-default">
                        <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e80b79f"
                            data-id="e80b79f" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div
                                    class="elementor-element elementor-element-74c314d shadepro-sticky-no elementor-widget elementor-widget-shortcode"
                                    data-id="74c314d" data-element_type="widget"
                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="shortcode.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-shortcode">
                                            <div class="_df_book df-lite df-container df-floating df-controls-bottom" id="df_15820"
                                                data-title="healthcare-system" _slug="15820" wpoptions="true" thumbtype="bg" df-parsed="true"
                                                style={{ position: "relative", overflow: "hidden", backgroundColor: "rgb(240, 246, 250)", minHeight: "300px", minWidth: "300px", height: "656px" }}>
                                                <p class="df-raw-loading">Please wait while flipbook is loading. For more related info, FAQs and
                                                    issues please refer to <a
                                                        href="https://wordpress.org/plugins/3d-flipbook-dflip-lite/#installation">DearFlip WordPress
                                                        Flipbook Plugin Help</a> documentation.</p>
                                                <div class="df-comment-popup"></div>
                                                <div class="loading-info">DearFlip: Loading PDF 54% ...</div><canvas width="1400" height="820"
                                                    class="df-3dcanvas" style={{ display: "block", width: "1120px", height: "656px" }}></canvas>
                                                <div class="df-3dcanvas df-csscanvas"
                                                    style={{ overflow: "hidden", transformStyle: "preserve-3d", position: "absolute", top: "0px", pointerEvents: "none", width: "1120px", height: "656px", perspective: "1224.11px" }}>
                                                    <div
                                                        style={{ transformStyle: "preserve-3d", width: "1120px", height: "656px", transform: "translate3d(0px, 0px, 1224.11px) matrix3d(1, 0, 0, 0, 0, - 1, 0, 0, 0, 0, 1, 0, 0, -10.6835, -656.088, 1) translate3d(560px, 328px, 0px)" }}>
                                                        <div class="df-page-content df-page-content-left"
                                                            style={{ position: " absolute", transform: "translate3d(-50 %, -50 %, 0px) matrix3d(0.999997, 0, 0.00234259, 0, 0, - 1, 0, 0, -0.00234259, 0, 0.999997, 0, -104.781, 0, 0.437286, 1); width: 210px; left: -105px; height: 297px" }}>
                                                        </div>
                                                        <div class="df-page-content df-page-content-right"
                                                            style={{ position: "absolute", transform: "translate3d(-50 %, -50 %, 0px) matrix3d(0.999996, 0, - 0.00265675, 0, 0, -1, 0, 0, 0.00265675, 0, 0.999996, 0, -105.248, 0, 0.495929, 1)", width: "210px", left: "105px", height: "297px" }}>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="df-share-wrapper" style={{ display: "none" }}>
                                                    <div class="df-share-box"><span class="df-share-title">Share</span><textarea
                                                        class="df-share-url"></textarea>
                                                        <div class="df-share-button df-share-facebook ti-facebook"></div>
                                                        <div class="df-share-button df-share-twitter ti-twitter-alt"></div><a
                                                            class="df-share-button df-share-mail ti-email"
                                                            href="mailto:?subject=I%20wanted%20you%20to%20see%20this%20FlipBook&amp;body=Check%20out%20this%20site"
                                                            target="_blank"></a>
                                                    </div>
                                                </div>
                                                <div class="df-ui-wrapper df-ui-controls">
                                                    <div class="df-ui-btn df-ui-prev df-ui-alt ti-angle-left disabled" title="Previous Page">
                                                        <span>Previous Page</span></div>
                                                    <div class="df-ui-btn df-ui-page"><input id="df_book_page_number" type="text" /><label
                                                        for="df_book_page_number">1/16</label></div>
                                                    <div class="df-ui-btn df-ui-next df-ui-alt ti-angle-right" title="Next Page"><span>Next
                                                        Page</span></div>
                                                    <div class="df-ui-btn df-ui-outline ti-menu-alt" title="Toggle Outline/Bookmark"
                                                        style={{ display: "none" }}><span>Toggle Outline/Bookmark</span></div>
                                                    <div class="df-ui-btn df-ui-thumbnail ti-layout-grid2" title="Toggle Thumbnails"><span>Toggle
                                                        Thumbnails</span></div>
                                                    <div class="df-ui-btn df-ui-zoomin ti-zoom-in" title="Zoom In"><span>Zoom In</span></div>
                                                    <div class="df-ui-btn df-ui-zoomout ti-zoom-out disabled" title="Zoom Out"><span>Zoom
                                                        Out</span></div>
                                                    <div class="df-ui-btn df-ui-fullscreen ti-fullscreen" title="Toggle Fullscreen"><span>Toggle
                                                        Fullscreen</span></div>
                                                    <div class="df-ui-btn df-ui-share ti-sharethis" title="Share"><span>Share</span></div>
                                                    <div class="df-ui-btn df-ui-more ti-more-alt">
                                                        <div class="more-container"><a download="" target="_blank"
                                                            class="df-ui-btn df-ui-download ti-download"
                                                            href="https://www.optimus-health.com/wp-content/uploads/2021/11/Optimus-Broucher-2.pdf"
                                                            title="Download PDF File"><span>Download PDF File</span></a>
                                                            <div class="df-ui-btn df-ui-pagemode ti-file" title="Single Page Mode"><span>Single Page
                                                                Mode</span></div>
                                                            <div class="df-ui-btn df-ui-start ti-angle-double-left" title="Goto First Page"><span>Goto
                                                                First Page</span></div>
                                                            <div class="df-ui-btn df-ui-end ti-angle-double-right" title="Goto Last Page"><span>Goto
                                                                Last Page</span></div>
                                                            <div class="df-ui-btn df-ui-sound ti-volume" title="Turn on/off Sound"><span>Turn on/off
                                                                Sound</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="df-ui-btn df-ui-prev ti-angle-left" title="Previous Page" style={{ display: "none" }}>
                                                    <span>Previous Page</span></div>
                                                <div class="df-ui-btn df-ui-next ti-angle-right" title="Next Page"><span>Next Page</span></div>
                                                <div class="df-ui-wrapper df-ui-zoom"></div>
                                                <div class="df-outline-container df-sidemenu">
                                                    <div class="df-ui-btn df-ui-sidemenu-close ti-close"></div>
                                                    <div class="df-outline-wrapper"></div>
                                                </div>
                                            </div>
                                            <script async="" data-cfasync="false"
                                                src="./Healthcare System Broucher - Optimus_files/pdf.min.js.download"></script>
                                            <script async="" data-cfasync="false"
                                                src="./Healthcare System Broucher - Optimus_files/mockup.min.js.download"></script>
                                            <script async="" data-cfasync="false"
                                                src="./Healthcare System Broucher - Optimus_files/three.min.js.download"></script>
                                            {/* <script class="df-shortcode-script"
                                                type="application/javascript">window.option_df_15820 = {"outline": [], "backgroundColor": "#F0F6FA", "forceFit": "true", "autoEnableOutline": "false", "autoEnableThumbnail": "false", "overwritePDFOutline": "false", "direction": "1", "pageSize": "0", "source": "https:\/\/www.optimus-health.com\/wp-content\/uploads\/2021\/11\/Optimus-Broucher-2.pdf", "wpOptions": "true" }; if (window.DFLIP && window.DFLIP.parseBooks) {window.DFLIP.parseBooks(); }</script> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                </section >
            </div >
        </div >

    )
}
