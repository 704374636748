import React, { useState } from 'react'
import Header from './header/Header'
import { Outlet } from 'react-router'
import Footer from './footer/Footer'
import { ToastContainer } from 'react-toastify'
import Loader from '../components/Loader'
// import SideBar from '../components/SideBar'

export default function Layout() {

    const [sideBar, setSideBar] = useState(false)

    return (
        <>
            {/* <SideBar show={sideBar} setShow={setSideBar} /> */}
            {/* <div id='page'> */}
            <ToastContainer />
            {/* <Loader /> */}
            <Header setShow={setSideBar} />
            <Outlet />
            <Footer />
            {/* </div> */}
        </>
    )
}
