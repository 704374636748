import React, { useEffect } from 'react'
import "../../assets/css/post-15569.css"
import ReachUsBtn from '../../components/ReachUsBtn'
import ScheduleDemoSection from '../../components/ScheduleDemoSection'

export default function IP() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div data-elementor-type="wp-page" data-elementor-id="15569" class="elementor elementor-15569">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-f2c9886 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="f2c9886" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-871b5fe"
                        data-id="871b5fe" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-e95b6a5 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="e95b6a5" data-element_type="section"
                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4523e26"
                                        data-id="4523e26" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-be55556 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="be55556" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Admission, Discharge, and Transfer
                                                        (ADT)</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-253d43a shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="253d43a" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p>&nbsp;We realize how many details are required to administrate the process of patients
                                                        admission to discharge.</p>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-8e36677 elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                                data-id="8e36677" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <ul class="elementor-icon-list-items">
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">We realize how many details are required to
                                                                administrate the process of patients' admission to discharge. </span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">We keep track of admission details as per each user
                                                                role without being overwhelmed with them.</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Starting with the admission advice, pre-admission
                                                                process, to the medical and financial discharge</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">We keep track of patient transfers, bed management
                                                                and facilities planning. </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ReachUsBtn />
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a36767c"
                                        data-id="a36767c" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-c87ef19 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="c87ef19" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/module_7-p6wi4dbkqqhdspr50qbrqy3d6wl843wpprwblxv1ts.png"
                                                        title="module_7" alt="module_7" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-6e12400 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInDown"
                                                data-id="6e12400" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1040" height="999"
                                                        src="/assets/images/module_7b.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 1040px) 100vw, 1040px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-214c210 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInDown"
                                                data-id="214c210" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="840" height="555"
                                                        src="/assets/images/module_7a.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 840px) 100vw, 840px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-e3f5c83 elementor-section-height-full elementor-section-boxed elementor-section-height-default elementor-section-items-middle shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="e3f5c83" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7baad9d"
                        data-id="7baad9d" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                                class="elementor-element elementor-element-946f381 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                data-id="946f381" data-element_type="widget"
                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">Operation Theatre Management </h2>
                                </div>
                            </div>
                            <div
                                class="elementor-element elementor-element-e5e94a1 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                data-id="e5e94a1" data-element_type="widget"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    Operating Theatre module helps in managing the operating rooms of the healthcare establishment. </div>
                            </div>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-8c5ce7f elementor-reverse-mobile elementor-section-height-min-height elementor-section-boxed elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="8c5ce7f" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d77f717"
                                        data-id="d77f717" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-2a8b98e elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                                data-id="2a8b98e" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <ul class="elementor-icon-list-items">
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Operating Theatre module helps with management the
                                                                operating rooms of the healthcare providers</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">The module supports scheduling of the operation
                                                                theatre rooms and assignment of surgery performers. </span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Offers the full logging of operation notes starting
                                                                during pre-op, inter-op, post-op notes, vitals, checklists and material consumption.
                                                            </span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Information are easily shared and contributed among
                                                                OT teams and providers.</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d427345"
                                        data-id="d427345" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-5ec5014 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="5ec5014" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/Optimus-Modules-_Operation-theater-management.svg"
                                                        title="Optimus Modules -_Operation theater management"
                                                        alt="Optimus Modules -_Operation theater management" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-c037b83 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="c037b83" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6ac3921"
                        data-id="6ac3921" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                                class="elementor-element elementor-element-5db4d1e shadepro-sticky-no elementor-widget elementor-widget-heading"
                                data-id="5db4d1e" data-element_type="widget"
                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">Nursing, Ward Management &amp; eMAR</h2>
                                </div>
                            </div>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-e63c25a elementor-reverse-mobile elementor-section-height-min-height elementor-section-boxed elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="e63c25a" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-adcf522"
                                        data-id="adcf522" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-ec1d294 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="ec1d294" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/Optimus-Modules-_EMAR.svg"
                                                        title="Optimus Modules -_EMAR" alt="Optimus Modules -_EMAR" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-658f524"
                                        data-id="658f524" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-d0a1d2b elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                                data-id="d0a1d2b" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <ul class="elementor-icon-list-items">
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Nursing and Ward management module enables the
                                                                nurses to perform their day-to-day activities from a single window screen without having
                                                                to navigate through menus and screens. </span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Nurses can keep attention to details with fully
                                                                automated process which includes preparing consents forms, different assessments, intake
                                                                and output while following up necessary medical procedures. </span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">eMAR manages the process of medication starting
                                                                from medication reconciliation to the administering of medicine to the patient. </span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Each order is tracked with the different status
                                                                such as; dispensed, due for administration , missed dose, delayed does , future dose
                                                                etc.</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <ScheduleDemoSection />
        </div>
    )
}
