import React, { useEffect } from 'react'
import ReachUsBtn from '../components/ReachUsBtn'
import ScheduleDemoSection from '../components/ScheduleDemoSection'

export default function Home() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div data-elementor-type="wp-page" data-elementor-id="14193" class="elementor elementor-14193">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-f2c9886 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="f2c9886" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-871b5fe"
                        data-id="871b5fe" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-e95b6a5 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="e95b6a5" data-element_type="section"
                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4523e26"
                                        data-id="4523e26" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-be55556 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="be55556" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Healthcare management
                                                        made easy</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-253d43a shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="253d43a" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Optimus is built from the ground up as a comprehensive solution for better healthcare
                                                    management. </div>
                                            </div>
                                            <ReachUsBtn />
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-101bb66"
                                        data-id="101bb66" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-ca1aa6a shadepro-sticky-no elementor-widget elementor-widget-image"
                                                data-id="ca1aa6a" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1024" height="868"
                                                        src="/assets/images/intro-1024x868.png"
                                                        class="attachment-large size-large" alt="" loading="lazy"
                                                        sizes="(max-width: 1024px) 100vw, 1024px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-ea823a3 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="ea823a3" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="612" height="657"
                                                        src="/assets/images/intro_c.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 612px) 100vw, 612px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-a8f09e5 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="a8f09e5" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="342" height="390"
                                                        src="/assets/images/intro_b.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 342px) 100vw, 342px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-b812d0c elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInDown"
                                                data-id="b812d0c" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="669" height="729"
                                                        src="/assets/images/intro_a.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 669px) 100vw, 669px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section
                class="elementor-section elementor-top-section elementor-element elementor-element-57a045c elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="57a045c" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-29ebc3d"
                        data-id="29ebc3d" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-d9c7454 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="d9c7454" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4f49517"
                                        data-id="4f49517" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-4bdcbfb elementor-aspect-ratio-169 shadepro-sticky-no elementor-widget elementor-widget-video"
                                                data-id="4bdcbfb" data-element_type="widget"
                                                data-settings="{&quot;youtube_url&quot;:&quot;https:\/\/youtu.be\/0menlljWXN0&quot;,&quot;video_type&quot;:&quot;youtube&quot;,&quot;controls&quot;:&quot;yes&quot;,&quot;aspect_ratio&quot;:&quot;169&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="video.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-wrapper elementor-fit-aspect-ratio elementor-open-inline">
                                                        <iframe class="elementor-video" frameborder="0" allowfullscreen="1"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                            title="Healthcare management is now easier" width="640" height="360"
                                                            src="/assets/images/0menlljWXN0.html"
                                                            id="widget2" data-gtm-yt-inspected-10="true"></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section> */}
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-f263cfb elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="f263cfb" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-cbb5a99"
                        data-id="cbb5a99" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-950c012 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="950c012" data-element_type="section"
                                data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7507367 animated fadeInLeft"
                                        data-id="7507367" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;fadeInLeft&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-83e7619 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="83e7619" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">What is Optimus?
                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-f5947a0 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="f5947a0" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <h5>Patient-focused EHR</h5>
                                                    Add, access, and manage patients’ electronic health records all in one place.
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-3ffc5a6 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="3ffc5a6" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <h5>Revenue Cycle Management</h5>
                                                    don’t miss any cash! Control income, keep patient accounts, and track insurance payments.
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-f79eab8 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="f79eab8" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <h5>A Cloud-based Solution</h5>
                                                    No need to invest in IT infrastructure. Simply access our cloud-based application from
                                                    anywhere
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-b6ec553"
                                        data-id="b6ec553" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-e200e98 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInRight"
                                                data-id="e200e98" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInRight&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/doctor_stock-p6uynkuact0y5y7cvlv4wtw3nmiofksb6kr64uivwg.png"
                                                        title="doctor_stock" alt="doctor_stock" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-95e49f1 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="95e49f1" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-35fa94f"
                        data-id="35fa94f" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-c3ec530 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="c3ec530" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4837cd6"
                                        data-id="4837cd6" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-37630b7 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="37630b7" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1024" height="852"
                                                        src="/assets/images/feature_1_lite-1024x852.png"
                                                        class="attachment-large size-large" alt="" loading="lazy"
                                                        sizes="(max-width: 1024px) 100vw, 1024px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-726267d"
                                        data-id="726267d" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-fac8ca6 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="fac8ca6" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Revenue Cycle Management
                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-e09922d shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="e09922d" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    don’t miss any cash, control income, keep patient accounts, track insurance payments
                                                </div>
                                            </div>
                                            <ReachUsBtn />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-95aff9e elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="95aff9e" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-00abe4b"
                        data-id="00abe4b" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-dbfb7bc elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="dbfb7bc" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5aca7fb"
                                        data-id="5aca7fb" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-f4d7f75 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="f4d7f75" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Patient focused EHR</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-de6a7ee shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="de6a7ee" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Get the complete medical profile in one place
                                                </div>
                                            </div>
                                            <ReachUsBtn />
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-8723f36"
                                        data-id="8723f36" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-03a82ab shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="03a82ab" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="851" height="938"
                                                        src="/assets/images/feature_2_lite.png"
                                                        class="attachment-large size-large" alt="" loading="lazy"
                                                        sizes="(max-width: 851px) 100vw, 851px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-48c3551 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="48c3551" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a89bcc7"
                        data-id="a89bcc7" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-7a583f8 elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="7a583f8" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4e64ea2"
                                        data-id="4e64ea2" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-93d2360 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="93d2360" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Complete transparency</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-c6c2123 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="c6c2123" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Get access to all your hospital’s medical and financial data
                                                </div>
                                            </div>
                                            <ReachUsBtn />
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-80a5813"
                                        data-id="80a5813" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-dd67f04 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="dd67f04" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1024" height="723"
                                                        src="/assets/images/feature_3_lite-1024x723.png"
                                                        class="attachment-large size-large" alt="" loading="lazy"
                                                        sizes="(max-width: 1024px) 100vw, 1024px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-cf44cb3 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="cf44cb3" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-18e65f5"
                        data-id="18e65f5" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-5cb07e2 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="5cb07e2" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-258b3d6"
                                        data-id="258b3d6" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-748a2e1 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="748a2e1" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1024" height="866"
                                                        src="/assets/images/feature_4_lite-1024x866.png"
                                                        class="attachment-large size-large" alt="" loading="lazy"
                                                        sizes="(max-width: 1024px) 100vw, 1024px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d5815b0"
                                        data-id="d5815b0" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-045e574 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="045e574" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Completely Cloud-based</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-56d8570 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="56d8570" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Easily access your cloud-based application everywhere </div>
                                            </div>
                                            <ReachUsBtn />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-bbca7fa elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="bbca7fa" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2972493"
                        data-id="2972493" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-6be2899 elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="6be2899" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ab37480"
                                        data-id="ab37480" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-070ee01 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="070ee01" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Patient focused EHR</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-9e0f8c6 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="9e0f8c6" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p>Get the complete medical profile in one place</p>
                                                </div>
                                            </div>
                                            <ReachUsBtn />
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-0deb9f2"
                                        data-id="0deb9f2" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-a38add8 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="a38add8" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1024" height="802"
                                                        src="/assets/images/feature_5_lite-1024x802.png"
                                                        class="attachment-large size-large" alt="" loading="lazy"
                                                        sizes="(max-width: 1024px) 100vw, 1024px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <ScheduleDemoSection />
        </div>

    )
}
