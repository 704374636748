import React from 'react'
import { Link } from 'react-router-dom'

export default function ScheduleDemoSection() {
    return (
        <section class="elementor-section elementor-top-section elementor-element elementor-element-2ae239e elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no" >
            <div class="elementor-container elementor-column-gap-default">
                <div
                    class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-64e523b"
                    data-id="64e523b" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                        <div class="elementor-element elementor-element-24cfadb shadepro-sticky-no elementor-widget elementor-widget-heading" >
                            <div class="elementor-widget-container">
                                <h2 class="elementor-heading-title elementor-size-default">Schedule a Demo</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-dda2761">
                    <div class="elementor-widget-wrap elementor-element-populated">
                        <div class="elementor-element elementor-element-92ebe09 shadepro-sticky-no elementor-widget elementor-widget-shadepro-btn" >
                            <div class="elementor-widget-container">
                                <div class="shadepro-btn-wrapper enable-icon-box-no">
                                    <Link class="shadepro-btn btn-type-boxed elementor-animation-float"
                                        to="/contact">
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
