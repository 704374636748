import React, { useEffect } from 'react'
import "../assets/css/post-291.css"

export default function AboutUs() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div data-elementor-type="wp-page" data-elementor-id="291" class="elementor elementor-291">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-f967fd3 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="f967fd3" data-element_type="section"
                data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                style={{ width: "1519px", left: "0px" }}>
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-0e88b88"
                        data-id="0e88b88" data-element_type="column">
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                    <div
                        class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-139f61f"
                        data-id="139f61f" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                                class="elementor-element elementor-element-c3ec6a5 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                data-id="c3ec6a5" data-element_type="widget"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">Our company
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-dd15e2b"
                        data-id="dd15e2b" data-element_type="column">
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-4f15cdb elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="4f15cdb" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c482a4f"
                        data-id="c482a4f" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-57460bb elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="57460bb" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-dd0bd29"
                                        data-id="dd0bd29" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-99bd382 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="99bd382" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="600" height="400"
                                                        src="/assets/images/ALL-COUNTRIES.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 600px) 100vw, 600px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-55fd8e2"
                                        data-id="55fd8e2" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-0ff338c shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="0ff338c" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    We noticed a gap in the market with healthcare systems being highly inefficient, and based on
                                                    long outdated technologies and methodologies. This leads not only to bad business but, more
                                                    critically, to people not getting their healthcare when they most need it. </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-c6c55d6 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="c6c55d6" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">

                                                    With Optimus, we aim to utilize thoughtful design, scalable implementation, and years of
                                                    enterprise software experience to provide a system that can truly enact tangible and
                                                    measurable change on to healthcare providers. </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
