import React, { useEffect } from 'react'
import "../../assets/css/post-15670.css"

export default function FM() {
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div data-elementor-type="wp-page" data-elementor-id="15670" class="elementor elementor-15670">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-ff1155e elementor-section-height-min-height elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-items-middle shadepro-sticky-no"
                data-id="ff1155e" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-79bfabf"
                        data-id="79bfabf" data-element_type="column"
                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-8aa3e41 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="8aa3e41" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d7e8a64"
                                        data-id="d7e8a64" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-cd24c12 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="cd24c12" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Control your business from
                                                        anywhere! </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-6bbc00e shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="6bbc00e" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Optimus ERP gives you the power and tools to control and manage your company in a way that
                                                    helps you to boost efficiency, maximize profits, anticipate success, accelerate growth. </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1a18253 animated fadeInUp"
                                        data-id="1a18253" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-1058cbb shadepro-sticky-no elementor-widget elementor-widget-image"
                                                data-id="1058cbb" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/ERP-ALL-IN-ONE_FIRST-PAGE-MAIN.svg" title="" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-c023d22 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
                data-id="c023d22" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7f73703"
                        data-id="7f73703" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-ac85b4e elementor-section-content-middle elementor-section-height-min-height elementor-section-boxed elementor-section-height-default shadepro-sticky-no"
                                data-id="ac85b4e" data-element_type="section"
                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f5d041a"
                                        data-id="f5d041a" data-element_type="column"
                                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-13858fe shadepro-sticky-no elementor-widget elementor-widget-image"
                                                data-id="13858fe" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/ERP-ALL-IN-ONE_Comprehensive-solution1.svg" title=""
                                                        alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c5c8472"
                                        data-id="c5c8472" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-a4bc239 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="a4bc239" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Comprehensive solution</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-cd57866 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="cd57866" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Optimus ERP lets you control finances, operations, and inventory in multiple languages and
                                                    currencies. </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-2785958 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
                                data-id="2785958" data-element_type="section"
                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c2f4196"
                                        data-id="c2f4196" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-6ed8f51 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="6ed8f51" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">General Ledger</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-3a37af6 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="3a37af6" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Using methods and accounting structures that are specific to your business needs, track and
                                                    analyze your financial data to increase your productivity. </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-b00f525"
                                        data-id="b00f525" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-3d9c0ef shadepro-sticky-no elementor-widget elementor-widget-image"
                                                data-id="3d9c0ef" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/ERP-ALL-IN-ONE_General-Ledger.svg" title="" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-ec0dcfe elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
                                data-id="ec0dcfe" data-element_type="section"
                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-93f0e62"
                                        data-id="93f0e62" data-element_type="column"
                                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-d625695 shadepro-sticky-no elementor-widget elementor-widget-image"
                                                data-id="d625695" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/ERP-ALL-IN-ONE_Accounts-Payable.svg" title="" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-95a4db0"
                                        data-id="95a4db0" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-7ddc0a5 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="7ddc0a5" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Accounts Payable</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-b6216b0 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="b6216b0" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Easily manage your cash flow, track and predict purchases, and analyze expenditures and
                                                    product purchases, while maintaining good vendor relationships.&nbsp; </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-471ef17 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
                                data-id="471ef17" data-element_type="section"
                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-b907052"
                                        data-id="b907052" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-4675017 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="4675017" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Accounts Receivable </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-61f7e73 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="61f7e73" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p>Optimus ERP allows you to gain complete control of your receivables and invoicing processes
                                                        and leverage automatic calculations that avoid errors and eliminate duplicate data entry.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c53dbf4"
                                        data-id="c53dbf4" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-27bce11 shadepro-sticky-no elementor-widget elementor-widget-image"
                                                data-id="27bce11" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/ERP-ALL-IN-ONE_Accounts-Receivable.svg" title=""
                                                        alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-db83e50 elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
                                data-id="db83e50" data-element_type="section"
                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ac9e26d"
                                        data-id="ac9e26d" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-e8faaa4 shadepro-sticky-no elementor-widget elementor-widget-image"
                                                data-id="e8faaa4" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/ERP-ALL-IN-ONE_inventory-Control.svg" title=""
                                                        alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-85c1ffb"
                                        data-id="85c1ffb" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-a0c9b9f shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="a0c9b9f" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Inventory Control</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-a18830f shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="a18830f" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Maintain accurate stock levels
                                                    and quickly process inventory transactions. </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-2a24d0b elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
                                data-id="2a24d0b" data-element_type="section"
                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c979011"
                                        data-id="c979011" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-2b26f39 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="2b26f39" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Cash and Banks</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-dd4fc8a shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="dd4fc8a" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    You need precise, up-to-date details on your company’s cash position, as well as credible
                                                    forecasts of future cash flows, to make successful decisions in today’s fast paced business
                                                    environment. </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-61d71f6"
                                        data-id="61d71f6" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-46224bb shadepro-sticky-no elementor-widget elementor-widget-image"
                                                data-id="46224bb" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/ERP-ALL-IN-ONE_Cash-and-Banks.svg" title="" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
