import React, { useState } from 'react'
import { useWindowScroll } from '../../hooks/useWindowsScroll'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

export default function Header({ setShow }) {
    const path = window.location.pathname

    const scrolled = useWindowScroll();

    const [showMenu, setShowMenu] = useState(false)
    const [showSubMenu, setShowSubMenu] = useState(false)

    return (
        <>
            <header class="site-header shadepro-elementor-header">
                <div data-elementor-type="wp-post" data-elementor-id="14050" class="elementor elementor-14050">
                    <section class="elementor-section elementor-top-section elementor-element elementor-element-ceb93a2 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no" data-id="ceb93a2" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-de35445" data-id="de35445" data-element_type="column">
                                <div class="elementor-widget-wrap">
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div data-elementor-type="wp-post" data-elementor-id="5868" class="elementor elementor-5868">
                    <section class="elementor-section elementor-top-section elementor-element elementor-element-0edb12b elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no" data-id="0edb12b" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                        <div class="elementor-container elementor-column-gap-no">
                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e9a63d6" data-id="e9a63d6" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-ac8afe9 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no" data-id="ac8afe9" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-b123b5e" data-id="b123b5e" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-5641e49 shadepro-sticky-no elementor-widget elementor-widget-ama-logo" data-id="5641e49" data-element_type="widget" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="ama-logo.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="ama-site-logo content-align-left">
                                                                <Link to="/" class="ama-site-logo-wrap">
                                                                    <span class="site-logo"><img decoding="async" src="/assets/images/Logo-01.png" alt="Optimus" class="navbar-brand__regular dark-logo" /></span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-766add2" data-id="766add2" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-11e39c3 elementor-widget__width-auto shadepro-sticky-no elementor-widget elementor-widget-shadepro-main-menu" data-id="11e39c3" data-element_type="widget" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="shadepro-main-menu.default">
                                                        <div class="elementor-widget-container">
                                                            <div class={`shadepro-main-menu-wrap navbar menu-align-start megamenu-width-container ${showMenu && "active"}`}>
                                                                <button class="navbar-toggler d-lg-none" onClick={() => setShowMenu(!showMenu)} type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                                                                    <span class="navbar-toggler-icon"></span>
                                                                </button>

                                                                <div class="navbar-inner">
                                                                    <div class="shadepro-mobile-menu"></div>
                                                                    <button class="navbar-toggler d-lg-none" onClick={() => setShowMenu(!showMenu)} type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                                                                        <span class="navbar-toggler-icon"></span>
                                                                    </button>
                                                                    <nav id="site-navigation" class="main-navigation ">
                                                                        <div class="shadepro-menu-container">
                                                                            <ul id="navbar-nav" class="navbar-nav" onClick={() => setShowMenu(false)}>
                                                                                <li class={`menu-item menu-item-type-post_type menu-item-object-page menu-item-home ${path === "/" && "current-menu-item"} page_item page-item-14193 current_page_item menu-item-14787 `}>
                                                                                    <Link to="/" aria-current="page">Home</Link></li>
                                                                                <li class={`menu-item menu-item-type-post_type menu-item-object-page menu-item-14789  ${path === "/aboutus" && "current-menu-item"}`}>
                                                                                    <Link to="/aboutus">About Us</Link></li>
                                                                                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-15663 ">
                                                                                    <Link to="/modules">Modules<span class="dropdownToggle">
                                                                                        <FontAwesomeIcon icon={faAngleDown} /></span><span class="dropdownToggle"><FontAwesomeIcon icon={faAngleDown} /></span>
                                                                                    </Link>
                                                                                    <ul class="sub-menu" style={{ display: "block" }}>
                                                                                        <li class={`menu-item menu-item-type-post_type menu-item-object-page menu-item-15650  ${path === "/pas" && "current-menu-item"}`}><Link to="/pas">Patient Administration</Link></li>
                                                                                        <li class={`menu-item menu-item-type-post_type menu-item-object-page menu-item-15648  ${path === "/EHR" && "current-menu-item"}`}><Link to="/EHR">Electronic Health Record</Link></li>
                                                                                        <li class={`menu-item menu-item-type-post_type menu-item-object-page menu-item-15649  ${path === "/rcm" && "current-menu-item"}`}><Link to="/rcm">Revenue Cycle Management</Link></li>
                                                                                        <li class={`menu-item menu-item-type-post_type menu-item-object-page menu-item-15647  ${path === "/pms" && "current-menu-item"}`}><Link to="/pms">Pharmacy Management</Link></li>
                                                                                        <li class={`menu-item menu-item-type-post_type menu-item-object-page menu-item-15646  ${path === "/lis" && "current-menu-item"}`}><Link to="/lis">Lab Information System</Link></li>
                                                                                        <li class={`menu-item menu-item-type-post_type menu-item-object-page menu-item-15645  ${path === "/ris" && "current-menu-item"}`}><Link to="/ris">Radiology Information System</Link></li>
                                                                                        <li class={`menu-item menu-item-type-post_type menu-item-object-page menu-item-15644  ${path === "/in-patient" && "current-menu-item"}`}><Link to="/in-patient">In Patient Modules</Link></li>
                                                                                        <li class={`menu-item menu-item-type-post_type menu-item-object-page menu-item-15673  ${path === "/finance" && "current-menu-item"}`}><Link to="/finance">Finance Modules</Link></li>
                                                                                    </ul>
                                                                                </li>
                                                                                <li class={`menu-item menu-item-type-post_type menu-item-object-page menu-item-14996  ${path === "/features" && "current-menu-item"}`}><Link to="/features">Features</Link></li>
                                                                                <li class={`menu-item menu-item-type-post_type menu-item-object-page menu-item-14791  ${path === "/contact" && "current-menu-item"}`}><Link to="/contact">Contact Us</Link></li>
                                                                            </ul>
                                                                        </div>
                                                                        <div class="elementor-element elementor-element-8359ab8 elementor-widget__width-auto elementor-show-phone shadepro-sticky-no elementor-widget elementor-widget-shadepro-btn my-3" data-id="8359ab8" data-element_type="widget" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="shadepro-btn.default">
                                                                            <div class="elementor-widget-container">
                                                                                <div class="shadepro-btn-wrapper enable-icon-box-no text-center">
                                                                                    <Link class="shadepro-btn btn-type-boxed elementor-animation-" to="/contact">
                                                                                        Request Demo
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="elementor-element elementor-element-8359ab8 elementor-widget__width-auto elementor-show-phone shadepro-sticky-no elementor-widget elementor-widget-shadepro-btn my-3" data-id="8359ab8" data-element_type="widget" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="shadepro-btn.default">
                                                                            <div class="elementor-widget-container">
                                                                                <div class="shadepro-btn-wrapper enable-icon-box-no text-center">
                                                                                    <Link class="shadepro-btn btn-type-boxed elementor-animation-" to="/signup">
                                                                                        Sign Up
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </nav>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-8359ab8 elementor-widget__width-auto elementor-hidden-phone shadepro-sticky-no elementor-widget elementor-widget-shadepro-btn" data-id="8359ab8" data-element_type="widget" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="shadepro-btn.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="shadepro-btn-wrapper enable-icon-box-no">
                                                                <Link class="shadepro-btn btn-type-boxed elementor-animation-" to="/contact">
                                                                    Request Demo
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div class="elementor-element elementor-element-8359ab8 elementor-widget__width-auto elementor-hidden-phone shadepro-sticky-no elementor-widget elementor-widget-shadepro-btn" data-id="8359ab8" data-element_type="widget" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="shadepro-btn.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="shadepro-btn-wrapper enable-icon-box-no">
                                                                <a class="shadepro-btn btn-type-boxed elementor-animation-" href="http://stores.scount.in/login">
                                                                    Login
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div class="elementor-element elementor-element-8359ab8 elementor-widget__width-auto elementor-hidden-phone shadepro-sticky-no elementor-widget elementor-widget-shadepro-btn" data-id="8359ab8" data-element_type="widget" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="shadepro-btn.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="shadepro-btn-wrapper enable-icon-box-no">
                                                                <Link class="shadepro-btn btn-type-boxed elementor-animation-" to="/signup">
                                                                    Sign Up
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </header>
        </>
    )
}
