import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import RouteIdentifier from './routing/RouteIdentifier';

function App() {
  return (
    <Router>
      <RouteIdentifier />
    </Router>
  );
}

export default App;
