const products = [
    {
        id: 1,
        name: 'Basic',
        price: 100,
        description: 'Product 1 description',
        image: 'https://picsum.photos/200/300'
    },
    {
        id: 2,
        name: 'Advance',
        price: 200,
        description: 'Product 2 description',
        image: 'https://picsum.photos/200/300'
    },
    {
        id: 3,
        name: 'Enterprise',
        price: 300,
        description: 'Product 3 description',
        image: 'https://picsum.photos/200/300'
    },
];

export default products;