import React from 'react'
import { useRef } from 'react';
import { Button, Col, Form, FormSelect, Modal, Row } from 'react-bootstrap';
import ReactSignatureCanvas from 'react-signature-canvas';
import products from '../data/products';
import db from '../services/firbaseConfig';
import { addDoc, collection } from 'firebase/firestore';
import { useNavigate } from 'react-router';

export default function Signup() {

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

    const emptyDetails = { name: "", email: "", phoneNumber: "", organization: "", mailingAddress: "", city: "", users: "", product: "", fax: "" };

    const [details, setDetails] = React.useState(emptyDetails);
    const [openModel, setOpenModel] = React.useState(false);
    const [showSignature, setShowSignature] = React.useState(false);
    const [signatureBinary, setSignatureBinary] = React.useState("");
    const [signed, setSigned] = React.useState(false);

    const sigCanvas = useRef({});
    const navigate = useNavigate()

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDetails({ ...details, [name]: value });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("Validation fails");
        } else {
            setOpenModel(true);
        }
    }

    const clear = () => sigCanvas.current.clear();

    // const base64ToArrayBuffer = (base64) => {
    //     var finalBinary = str2bin.strToBin(base64);
    //     setImageBinary(finalBinary);
    //     return finalBinary;
    // };

    const save = () => {
        const alp = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        const base = alp.replace(/^data:image.+;base64,/, "");
        console.log(base);
        console.log(alp);
        setSignatureBinary(alp);
        setSigned(true);
        // base64ToArrayBuffer(base);
    };

    const saveForm = async (note) => {
        const data = {
            name: details.name,
            email: details.email,
            phoneNumber: details.phoneNumber,
            organization: details.organization,
            mailingAddress: details.mailingAddress,
            city: details.city,
            users: details.users,
            product: details.product,
            fax: details.fax,
            signature: signatureBinary,
            date: date
        }
        await addDoc(collection(db, 'SubscriptionSignup'), data);
        setOpenModel(false);
        setDetails(emptyDetails);
        setSignatureBinary("");
        setSigned(false);
        navigate('/thankyou', { state: { type: 2 } });
    };

    const closeModel = () => {
        setOpenModel(false);
        // setDetails(emptyDetails);
        setSignatureBinary("");
        setSigned(false);
    }

    return (
        <div data-elementor-type="wp-page" data-elementor-id="330" class="elementor elementor-330">
            <section class="elementor-section elementor-top-section elementor-element elementor-element-7f7500f elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no" >
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-8cd82ac" >
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                    <div
                        class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-627be32"
                        data-id="627be32" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                                class="elementor-element elementor-element-6238231 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                data-id="6238231" data-element_type="widget"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">Sign Up</h2>
                                </div>
                            </div>
                            <div
                                class="elementor-element elementor-element-99ed5ea shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                data-id="99ed5ea" data-element_type="widget"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p style={{ textAlign: "center" }}>Optimum
                                        Health Information System
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-41fd038"
                        data-id="41fd038" data-element_type="column">
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-312b1eb elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="312b1eb" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-38621ee"
                        data-id="38621ee" data-element_type="column">
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                    <div
                        class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-0e03f95"
                        data-id="0e03f95" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                                class="elementor-element elementor-element-cbb6700 shadepro-sticky-no elementor-widget elementor-widget-shortcode"
                                data-id="cbb6700" data-element_type="widget"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="shortcode.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-shortcode">
                                        <div role="form" class="wpcf7" id="wpcf7-f15388-p330-o1" lang="en-US" dir="ltr">
                                            <div class="screen-reader-response">
                                                <p role="status" aria-live="polite" aria-atomic="true"></p>
                                                <ul></ul>
                                            </div>
                                            <Form onSubmit={handleSubmit} class="wpcf7-form init" data-status="init">
                                                <div style={{ display: "none" }}>
                                                    <input type="hidden" name="_wpcf7" value="15388" />
                                                    <input type="hidden" name="_wpcf7_version" value="5.6.4" />
                                                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                                    <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f15388-p330-o1" />
                                                    <input type="hidden" name="_wpcf7_container_post" value="330" />
                                                    <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                                </div>
                                                <div class="row shade-contact-form-2">
                                                    <div class="col-md-6">
                                                        <label>Name</label><br />
                                                        <span class="wpcf7-form-control-wrap" data-name="name-web">
                                                            <input type="text"
                                                                name="name" value={details.name} size="40"
                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true"
                                                                aria-invalid="false" placeholder=" name" onChange={handleInputChange} required />
                                                        </span>
                                                    </div>
                                                    <div class=" col-md-6">
                                                        <label>Organization</label><br />
                                                        <span class="wpcf7-form-control-wrap" data-name="subject">
                                                            <input type="text" name="organization"
                                                                value={details.organization} size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="true" aria-invalid="false" placeholder=" organization" onChange={handleInputChange} required />
                                                        </span>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label>Email</label><br />
                                                        <span class="wpcf7-form-control-wrap" data-name="email-web">
                                                            <input type="email"
                                                                name="email" value={details.email} size="40"
                                                                class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                                aria-required="true" aria-invalid="false" placeholder=" email" onChange={handleInputChange} required />
                                                        </span>
                                                    </div>
                                                    <div class=" col-md-4">
                                                        <label>Phone Number</label><br />
                                                        <span class="wpcf7-form-control-wrap" data-name="tel-web">
                                                            <input type="tel" name="phoneNumber"
                                                                value={details.phoneNumber} size="40"
                                                                class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel"
                                                                aria-invalid="false" placeholder=" phone number" onChange={handleInputChange} required />
                                                        </span>
                                                    </div>
                                                    <div class=" col-md-4">
                                                        <label>Fax</label><br />
                                                        <span class="wpcf7-form-control-wrap" data-name="subject">
                                                            <input type="text" name="fax"
                                                                value={details.fax} size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="true" aria-invalid="false" placeholder=" fax" onChange={handleInputChange} />
                                                        </span>
                                                    </div>
                                                    <div class=" col-md-12">
                                                        <label>Mailling Address</label><br />
                                                        <span class="wpcf7-form-control-wrap" data-name="subject">
                                                            <input type="text" name="mailingAddress"
                                                                value={details.mailingAddress} size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="true" aria-invalid="false" placeholder=" address" onChange={handleInputChange} required />
                                                        </span>
                                                    </div>
                                                    <div class=" col-md-4">
                                                        <label>City</label><br />
                                                        <span class="wpcf7-form-control-wrap" data-name="subject">
                                                            <input type="text" name="city"
                                                                value={details.city} size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="true" aria-invalid="false" placeholder=" city" onChange={handleInputChange} required />
                                                        </span>
                                                    </div>
                                                    <div class=" col-md-4">
                                                        <label>Users</label><br />
                                                        <span class="wpcf7-form-control-wrap" data-name="subject">
                                                            <input type="text" name="users"
                                                                value={details.users} size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="true" aria-invalid="false" placeholder="Type no of users" onChange={handleInputChange} required />
                                                        </span>
                                                    </div>
                                                    <div class=" col-md-4">
                                                        <label>Optimus Product</label><br />
                                                        <span class="wpcf7-form-control-wrap" data-name="subject">
                                                            <FormSelect name='product' value={details.product} required={true} class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" onChange={handleInputChange}>
                                                                <option value="">Select</option>
                                                                {products.map((product, index) => {
                                                                    return (
                                                                        <option value={product.id}>{product.name}</option>
                                                                    )
                                                                })}
                                                            </FormSelect>
                                                        </span>
                                                    </div>
                                                    <div class=" col-md-6">
                                                        <span class="wpcf7-form-control-wrap" data-name="subject">
                                                            <input type="checkbox" name="subject"
                                                                value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="true" aria-invalid="false" placeholder="tac" required />
                                                            <label>I agree <span><a target='_blank' href='/terms-conditions'>Terms and Conditions</a></span> </label>
                                                        </span>
                                                    </div>
                                                    {/* <div class="area col-md-12">
                                                        <label>Message</label><br />
                                                        <span class="wpcf7-form-control-wrap" data-name="message-web"><textarea name="message-web"
                                                            cols="40" rows="10"
                                                            class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            placeholder=" message"></textarea></span>
                                                    </div> */}
                                                    <div class="col-6">
                                                        <input type="submit" value="Sign Up" class="wpcf7-form-control has-spinner wpcf7-submit" /><span
                                                            class="wpcf7-spinner"></span>
                                                    </div>
                                                </div>
                                                <div class="wpcf7-response-output" aria-hidden="true"></div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-c29d3e2"
                        data-id="c29d3e2" data-element_type="column">
                        <div class="elementor-widget-wrap">
                        </div>
                    </div>
                </div>
            </section>
            {/* {openModel && (
                <div className="modalContainer">
                    <div className="modal_custom">
                        <div className='modal__body'>
                            <h2>Sign Up</h2>
                        </div>
                        <div className="modal__bottom">
                            <button onClick={() => setOpenModel(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )} */}
            <Modal size='lg' show={openModel}>
                <Modal.Body>
                    <div className='text-center'>
                        <h6>Optimum Systems </h6>
                        <h6>Health Information System </h6>
                        <h6> SaaS Subscription Agreement Form </h6>
                    </div>
                    <div className='form_details'>
                        <Row className='m-3'>
                            <Col md={6}>
                                <label>Name : {details.name}</label>
                                <label>Email : {details.email}</label>
                                <label>Fax : {details.fax}</label>
                                <label>City : {details.city}</label>
                            </Col>
                            <Col md={6}>
                                <label>Organization : {details.organization}</label>
                                <label>Phone Number : {details.phoneNumber}</label>
                                <label>Mailling Address : {details.mailingAddress}</label>
                                <label>Product : {details.product && products.find(prod => prod.id == details.product).name}</label>
                            </Col>
                        </Row>
                        <label>You have agreed <span><a target='_blank' href='/terms-conditions'>Terms and Conditions</a></span></label>
                    </div>
                    <div>
                        <p>By signing below, I represent and warrant that I have the authority to enter into this Agreement on behalf of myself and, if applicable, my organization. </p>
                    </div>
                    {!signed && (!showSignature ? <button className='btn-primary' onClick={() => setShowSignature(true)}>Sign Form</button> :
                        <>
                            <ReactSignatureCanvas penColor='black'
                                ref={sigCanvas}
                                canvasProps={{ width: 300, height: 100, className: 'sigCanvas mr-2' }} />
                            <button className='btn-danger mr-2' onClick={clear}>Clear</button>
                            <button className='btn-primary' onClick={save}>Save</button>
                        </>)}
                    {signed && <div className='form_details'>
                        <Row>
                            <Col md={6}>
                                <label>Name : {details.name}</label>
                                <label>Date : {date}</label>
                            </Col>
                            <Col md={6}>
                                <label>signature : <img src={signatureBinary} style={{ height: "35px" }} /></label>
                                <label>Title : {details.product && products.find(prod => prod.id == details.product).name}</label>
                            </Col>
                        </Row>
                    </div>}
                </Modal.Body>
                <Modal.Footer className='justify-content-start'>
                    <Button variant="secondary" onClick={closeModel}>
                        Close
                    </Button>
                    {signed && <Button variant="primary" onClick={saveForm}>
                        Save
                    </Button>}
                </Modal.Footer>
            </Modal>
        </div >
    )
}
