import React, { useEffect } from 'react'
import "../../assets/css/post-15561.css"
import ReachUsBtn from '../../components/ReachUsBtn'
import ScheduleDemoSection from '../../components/ScheduleDemoSection'

export default function RIS() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div data-elementor-type="wp-page" data-elementor-id="15561" class="elementor elementor-15561">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-f2c9886 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="f2c9886" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-871b5fe"
                        data-id="871b5fe" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-e95b6a5 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="e95b6a5" data-element_type="section"
                                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4523e26"
                                        data-id="4523e26" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-be55556 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="be55556" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Radiology information system</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-253d43a shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="253d43a" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    We understand how critical is the management of Radiology department resources is important.
                                                    You can improve the efficiency of the department, create more revenue and remove the cost of
                                                    the films if you use a proper RIS. Furthermore, you will maintain better patient coordination
                                                    and maintain better patient care.<br />
                                                    <br />
                                                    We offer a fully automated flow for the Imaging starting from booking equipment appointments
                                                    per the procedure’s time, to writing, validating and authorizing the final report.
                                                </div>
                                            </div>
                                            <ReachUsBtn />

                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-101bb66"
                                        data-id="101bb66" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-ca1aa6a shadepro-sticky-no elementor-widget elementor-widget-image"
                                                data-id="ca1aa6a" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1024" height="1024"
                                                        src="/assets/images/Optimus-Modules-_RIS.svg"
                                                        class="attachment-large size-large" alt="" loading="lazy" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-95e49f1 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="95e49f1" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-35fa94f"
                        data-id="35fa94f" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div
                                class="elementor-element elementor-element-fac8ca6 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                data-id="fac8ca6" data-element_type="widget"
                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">Features </h2>
                                </div>
                            </div>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-c3ec530 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="c3ec530" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4837cd6"
                                        data-id="4837cd6" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-e5d78fd shadepro-sticky-no elementor-widget elementor-widget-image"
                                                data-id="e5d78fd" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1024" height="1024"
                                                        src="/assets/images/Optimus-Modules-_PACS.svg"
                                                        class="attachment-large size-large" alt="" loading="lazy" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-726267d"
                                        data-id="726267d" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-19e34f4 elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                                data-id="19e34f4" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <ul class="elementor-icon-list-items">
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Integration with 3rd party PACS</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Complete management of the imaging process and
                                                                logging of activities </span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Build the result reports on your different
                                                                customized reports templates per examination </span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Publish the authorized results to the HIS </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <ScheduleDemoSection />
        </div>
    )
}
