import React, { useEffect } from 'react'
import "../assets/css/post-15162.css"

export default function ERP() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div data-elementor-type="wp-page" data-elementor-id="15162" class="elementor elementor-15162">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-ff1155e elementor-section-height-full elementor-section-boxed elementor-section-height-default elementor-section-items-middle shadepro-sticky-no"
                data-id="ff1155e" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-79bfabf"
                        data-id="79bfabf" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-8aa3e41 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="8aa3e41" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d7e8a64"
                                        data-id="d7e8a64" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-cd24c12 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="cd24c12" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Control your business from
                                                        anywhere! </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-6bbc00e shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="6bbc00e" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Optimus ERP gives you the power and tools to control and manage your company in a way that
                                                    helps you to boost efficiency, maximize profits, anticipate success, accelerate growth. </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-8cc1cf9 shadepro-sticky-no elementor-widget elementor-widget-shadepro-btn"
                                                data-id="8cc1cf9" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="shadepro-btn.default">
                                                <div class="elementor-widget-container">
                                                    <div class="shadepro-btn-wrapper enable-icon-box-no">
                                                        <a class="shadepro-btn btn-type-boxed elementor-animation-float"
                                                            href="https://www.optimus-health.com/contact/">


                                                            Learn More
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f297feb"
                                        data-id="f297feb" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-698483f shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="698483f" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" src="/assets/images/New_ERP.svg"
                                                        title="New_ERP" alt="New_ERP" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-25e67a5 elementor-section-height-full shadepro-gradient-till elementor-section-boxed elementor-section-height-default elementor-section-items-middle shadepro-sticky-no"
                data-id="25e67a5" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f94c96a"
                        data-id="f94c96a" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-ac85b4e elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="ac85b4e" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-cdcf1b1"
                                        data-id="cdcf1b1" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-fd01cba shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="fd01cba" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/module_1-p6wi2r1kz09lsw3sh14wgcssd0kguvhctrg7us9gjk.png"
                                                        title="module_1" alt="module_1" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-d19517c elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInDown"
                                                data-id="d19517c" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="828" height="870"
                                                        src="/assets/images/module_1a.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 828px) 100vw, 828px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-dd2287e elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInDown"
                                                data-id="dd2287e" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1127" height="725"
                                                        src="/assets/images/module_1b.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 1127px) 100vw, 1127px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c5c8472"
                                        data-id="c5c8472" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-a4bc239 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="a4bc239" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Comprehensive solution</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-cd57866 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="cd57866" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Optimus ERP lets you control finances, operations, and inventory in multiple languages and
                                                    currencies. </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-0c24ba8 elementor-section-height-full shadepro-gradient-till elementor-section-boxed elementor-section-height-default elementor-section-items-middle shadepro-sticky-no"
                data-id="0c24ba8" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-03b0f1f"
                        data-id="03b0f1f" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-16f7f83 elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="16f7f83" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-cf661fb"
                                        data-id="cf661fb" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-0724bfb shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="0724bfb" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Instant web access </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-1be631a shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="1be631a" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Optimus ERP provides access to business and transactional data at any time and from any place,
                                                    which is essential for supporting an increasingly mobile and geographically distributed
                                                    workforce. </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-2c21d86"
                                        data-id="2c21d86" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-a068ef2 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="a068ef2" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/module_1-p6wi2r1kz09lsw3sh14wgcssd0kguvhctrg7us9gjk.png"
                                                        title="module_1" alt="module_1" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-9417e64 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInDown"
                                                data-id="9417e64" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1287" height="1088"
                                                        src="/assets/images/feature_4_lite.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 1287px) 100vw, 1287px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-9378efb elementor-section-height-full shadepro-gradient-till elementor-section-boxed elementor-section-height-default elementor-section-items-middle shadepro-sticky-no"
                data-id="9378efb" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-97ef5d2"
                        data-id="97ef5d2" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-2abf985 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="2abf985" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-692601a"
                                        data-id="692601a" data-element_type="column"
                                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-64e0424 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="64e0424" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/feature_5_lite-p6v1p23kd4w8ex6glfhttpbug8wpygetwg63e2756o.png"
                                                        title="feature_5_lite" alt="feature_5_lite" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-2bc4ea4 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInDown"
                                                data-id="2bc4ea4" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="342" height="390"
                                                        src="/assets/images/intro_d.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 342px) 100vw, 342px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f0a2617"
                                        data-id="f0a2617" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-c271443 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="c271443" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">General Ledger</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-d9d1149 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="d9d1149" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p>Using methods and accounting structures that are specific to your business needs, track and
                                                        analyze your financial data to increase your productivity.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-b0d9818 elementor-section-height-full shadepro-gradient-till elementor-section-boxed elementor-section-height-default elementor-section-items-middle shadepro-sticky-no"
                data-id="b0d9818" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-563b5e2"
                        data-id="563b5e2" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-2785958 elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="2785958" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c2f4196"
                                        data-id="c2f4196" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-6ed8f51 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="6ed8f51" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Accounts Payable</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-3a37af6 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="3a37af6" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Easily manage your cash flow, track and predict purchases, and analyze expenditures and
                                                    product purchases, while maintaining good vendor relationships.&nbsp; </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-b00f525"
                                        data-id="b00f525" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-3d9c0ef shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="3d9c0ef" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/feature_2_lite-p6uzclbo839n4dvctf3q9inoqr8b9m35ydqbvxfqbk.png"
                                                        title="feature_2_lite" alt="feature_2_lite" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-71030ce elementor-section-height-full elementor-section-boxed elementor-section-height-default elementor-section-items-middle shadepro-sticky-no"
                data-id="71030ce" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6c00fbf"
                        data-id="6c00fbf" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-ec0dcfe elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="ec0dcfe" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-2a93ace"
                                        data-id="2a93ace" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-69b1959 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="69b1959" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/feature_1_lite-p6uytk3btv7m0nioyowp9tknov1qe9iuc65d17nqbk.png"
                                                        title="feature_1_lite" alt="feature_1_lite" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-95a4db0"
                                        data-id="95a4db0" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-7ddc0a5 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="7ddc0a5" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Accounts Receivable </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-b6216b0 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="b6216b0" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p>Optimus ERP allows you to gain complete control of your receivables and invoicing processes
                                                        and leverage automatic calculations that avoid errors and eliminate duplicate data entry.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-941a182 elementor-section-height-full elementor-section-boxed elementor-section-height-default elementor-section-items-middle shadepro-sticky-no"
                data-id="941a182" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6ee2c0b"
                        data-id="6ee2c0b" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-471ef17 elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="471ef17" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-b907052"
                                        data-id="b907052" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-4675017 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="4675017" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Inventory Control</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-61f7e73 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="61f7e73" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Maintain accurate stock levels
                                                    and quickly process inventory transactions. </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c53dbf4"
                                        data-id="c53dbf4" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-a76c248 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="a76c248" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/feature_6b-p6wi2kgpn60ljmdcjgaigwgk7bgwczr8guvthuj7r4.png"
                                                        title="feature_6b" alt="feature_6b" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-d80be32 elementor-section-height-full elementor-section-boxed elementor-section-height-default elementor-section-items-middle shadepro-sticky-no"
                data-id="d80be32" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-86103d3"
                        data-id="86103d3" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-db83e50 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="db83e50" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ac9e26d"
                                        data-id="ac9e26d" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-e8faaa4 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="e8faaa4" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/feature_6a-p6wi2jivgbzb80epoxvvwep3lxlj5ani4q8c0kklxc.png"
                                                        title="feature_6a" alt="feature_6a" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-85c1ffb"
                                        data-id="85c1ffb" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-a0c9b9f shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="a0c9b9f" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Cash and Banks</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-a18830f shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="a18830f" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p><span class="TextRun SCXW65670455 BCX0" lang="EN-GB"
                                                        data-contrast="auto"><span class="NormalTextRun SCXW65670455 BCX0">You need precise,
                                                            up-to-date details on your company’s cash position, as well as credible forecasts of
                                                            future cash flows, to make successful decisions in today’s fast paced business
                                                        </span></span><span class="TextRun SCXW65670455 BCX0" lang="EN-GB"
                                                            data-contrast="auto"><span
                                                                class="NormalTextRun SCXW65670455 BCX0">environment</span></span><span
                                                                    class="TextRun SCXW65670455 BCX0" lang="EN-GB" data-contrast="auto"><span
                                                                        class="NormalTextRun SCXW65670455 BCX0">.</span></span><span
                                                                            class="EOP SCXW65670455 BCX0" data-ccp-props="{">&nbsp;</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-1f06a40 elementor-section-height-full elementor-section-boxed elementor-section-height-default elementor-section-items-middle shadepro-sticky-no"
                data-id="1f06a40" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6f3ab02"
                        data-id="6f3ab02" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-2a24d0b elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="2a24d0b" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c979011"
                                        data-id="c979011" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-2b26f39 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="2b26f39" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Software as a Service (SaaS)</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-ff9d263 elementor-icon-list--layout-traditional elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                                data-id="ff9d263" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <ul class="elementor-icon-list-items">
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Less risk for business due to less investment in IT
                                                                infrastructure, support and one-time software license. </span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Business continuity due to high availability and
                                                                reliability of infrastructure </span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Access to regular updates and new features</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-61d71f6"
                                        data-id="61d71f6" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-46224bb shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="46224bb" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/feature_4_lite-p6v1otn0nmknifiqytu6p9gp3s2f16h8vaaq2kjoqo.png"
                                                        title="feature_4_lite" alt="feature_4_lite" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
