import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router';

export default function ThankyouScreen() {
    const location = useLocation();
    const propsData = location.state;

    const navigate = useNavigate()

    const [message, setMessage] = React.useState("");

    useEffect(() => {
        if (propsData) {
            if (propsData.type == 1) {
                setMessage("We appreciate that you’ve taken the time to write us. We’ll get back to you very soon. Please come back and see us often.");
            } else {
                setMessage("We have received your message and would like to thank you for writing to us. If your inquiry is urgent, please use the phone number listed (+974 7771 0038 ) to talk to one of our customer care executives. Otherwise, we will reply by email as soon as possible.");
            }
        } else {
            navigate("/")
        }
        window.scrollTo(0, 0);
    }, [])

    return (
        <div class="shadepro-page-builder-wrapper">

            <div data-elementor-type="wp-page" data-elementor-id="3130" class="elementor elementor-3130">
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-2a2b562 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                    data-id="2a2b562" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                    <div class="elementor-container elementor-column-gap-default">
                        <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8910ca0"
                            data-id="8910ca0" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div
                                    class="elementor-element elementor-element-c89fc6d shadepro-sticky-no elementor-widget elementor-widget-heading"
                                    data-id="c89fc6d" data-element_type="widget"
                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Thank You</h2>
                                    </div>
                                </div>
                                <div
                                    class="elementor-element elementor-element-7a6e847 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                    data-id="7a6e847" data-element_type="widget"
                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p>for {propsData.type === 1 ? "writing us" :  "signing up"} </p>
                                        <p>Optimus Health Information System </p>
                                    </div>
                                </div>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-700fe16 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no" >
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div
                                            class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-359432e"
                                            data-id="359432e" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-499cd9f shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            {message}
                                                        </p>
                                                        <p>
                                                            <strong>Optimus</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
