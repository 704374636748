import React, { useEffect } from 'react'
import "../assets/css/post-3130.css"

export default function TermsAndConditions() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div class="shadepro-page-builder-wrapper">

            <div data-elementor-type="wp-page" data-elementor-id="3130" class="elementor elementor-3130">
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-2a2b562 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                    data-id="2a2b562" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                    <div class="elementor-container elementor-column-gap-default">
                        <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8910ca0"
                            data-id="8910ca0" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div
                                    class="elementor-element elementor-element-c89fc6d shadepro-sticky-no elementor-widget elementor-widget-heading"
                                    data-id="c89fc6d" data-element_type="widget"
                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h2 class="elementor-heading-title elementor-size-default">Terms &amp; Conditions</h2>
                                    </div>
                                </div>
                                <div
                                    class="elementor-element elementor-element-7a6e847 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                    data-id="7a6e847" data-element_type="widget"
                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p>Optimus Health Information System </p>
                                        <p>SaaS Subscription Terms and Conditions</p>
                                    </div>
                                </div>
                                <section
                                    class="elementor-section elementor-inner-section elementor-element elementor-element-700fe16 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                >
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div
                                            class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-359432e"
                                            data-id="359432e" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    class="elementor-element elementor-element-499cd9f shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            These terms and conditions (the "Agreement") govern your access to and use of
                                                            [Your HIS Product Name] ("the Service"), provided by [Your Company Name] ("us", "we", or "our"). By accessing or using
                                                            the Service, you agree to be bound by this Agreement.
                                                            If you do not agree with any part of the terms, you may not access or use the Service.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Account Registration and Access</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            To use the Service, you must register for an account. You agree to provide accurate, current,
                                                            and complete information during the registration process and to update such information as needed. You are responsible for maintaining the
                                                            confidentiality of your account login information and for all activities that occur under your account.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Allowed Number of Users</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            Your subscription to the Service includes access for a specified number of users, as detailed in your subscription plan. You may not exceed the allowed number of users without upgrading your subscription plan or obtaining written consent from us. Each user must have their own unique login credentials and may not share access with others.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Service Subscription and Fees</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            The Service is offered on a subscription basis. You agree to pay the subscription fees and any other charges incurred in connection with your account. Fees are non-refundable, except as required by law or as otherwise stated in this Agreement. We reserve the right to change our fees and to introduce new charges at any time, upon notice to you.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Payment Terms</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            You authorize us to charge your credit card or other payment methods on file for all fees and charges related to your subscription. You are responsible for ensuring that your payment information is accurate and up-to-date. We may suspend or terminate your access to the Service if your payment is overdue.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Limited Warranty</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            We warrant that the Service will substantially conform to the published specifications and will be provided in a professional and workmanlike manner. However, we do not warrant that the Service will be error-free or that any errors will be corrected. You acknowledge that the Service is a medical application and that we are not responsible for any incorrect or incomplete information, diagnosis, or treatment recommendations. You are responsible for verifying the accuracy and completeness of any information provided through the Service before relying on it. In the event of a breach of this warranty, your sole and exclusive remedy will be, at our option, to either correct the non-conformity or to terminate the Service and provide a pro-rata refund of any prepaid fees for the remaining term of the subscription.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Acceptable Use Policy</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            You agree to use the Service in compliance with all applicable laws and regulations and in accordance with this Acceptable Use Policy. You may not:
                                                        </p>
                                                        <ol type='a'>
                                                            <li> Use the Service to transmit, store, or distribute any viruses, malware, or other harmful software. </li>
                                                            <li>Use the Service to engage in any activity that infringes upon the intellectual property rights of others. </li>
                                                            <li>Attempt to gain unauthorized access to any system, data, or account related to the Service. </li>
                                                            <li>Use the Service to engage in any activity that violates any applicable healthcare-specific regulations, such as HIPAA or GDPR.</li>
                                                        </ol>
                                                        <p>
                                                            We reserve the right to investigate and take appropriate action, including suspending or terminating your access to the Service, if we believe you have violated this Acceptable Use Policy.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Usage and Restrictions</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            You may use the Service solely for your internal business purposes and in accordance with this
                                                            Agreement. You may not resell, sublicense, or otherwise make the Service available to any third party. You agree not to use the Service for any unlawful purposes or in a manner that violates any applicable laws or regulations.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Intellectual Property Rights</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            We and our licensors own all rights, title, and interest in and to the Service, including all associated intellectual property rights. You agree not to reverse engineer, decompile, or disassemble the Service, except as permitted by law.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Data Ownership, Protection, and Compliance </h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            You retain all rights to your data that is processed, stored, or transmitted through the Service. We will maintain appropriate administrative, physical, and technical safeguards to protect the security, confidentiality, and integrity of your data, including protected health information (PHI). We will comply with applicable healthcare-specific regulations, such as HIPAA or GDPR, in our handling and processing of your data. We will not use your data for any purpose other than providing the Service or as required by law. In the event of a data breach involving your data, we will promptly notify you and take appropriate actions as required by applicable laws and regulations.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Service Availability and Limited Liability </h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            We will use commercially reasonable efforts to provide the Service with the performance and availability standards as specified in the Service Level Agreement (SLA). However, we do not guarantee uninterrupted or error-free operation of the Service. In no event will we be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Service, including, but not limited to, loss of data, loss of business, or loss of profits, even if we have been advised of the possibility of such damages. Our total aggregate liability for any claims arising out of or in connection with this Agreement will not exceed the amount paid by you for the Service during the twelve (12) month period prior to the event giving rise to the liability.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Termination and Suspension </h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            We may terminate or suspend your access to the Service at any time, with or without cause or notice, if we believe that you have breached this Agreement or if your account is overdue. Upon termination, your right to use the Service will cease immediately, and you must promptly delete any data or content you have obtained from the Service. We reserve the right to disconnect your access to the data in the event of non-payment or breach of this Agreement.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Governing Law and Dispute Resolution </h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            This Agreement and any disputes arising out of or in connection with it will be governed by the laws of [Jurisdiction], without regard to its conflict of law provisions. Any disputes arising out of or in connection with this Agreement will be subject to the exclusive jurisdiction of the courts located in [Location]. The parties agree to attempt to resolve any disputes amicably before resorting to litigation.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Changes to the Agreement </h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            We reserve the right to modify this Agreement at any time. We will notify you of any changes by posting the new Agreement on our website or by sending you an email. Your continued use of the Service after the changes become effective constitutes your acceptance of the new Agreement.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Miscellaneous</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            his Agreement constitutes the entire understanding between you and us regarding the subject matter hereof and supersedes all prior and contemporaneous agreements, representations, and understandings. If any provision of this Agreement is held to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
                                                        </p>
                                                        <p>
                                                            If you have any questions about this Agreement, please contact us at [Your Company Contact Information].
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Cancellation Policy</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p><strong class="privacy-policy-tutorial">Cancellation Process:</strong></p>
                                                        <p>Users can cancel their subscription at any time by accessing their account settings or contacting customer support.</p>
                                                        <p><strong class="privacy-policy-tutorial">Effective Cancellation Date:</strong></p>
                                                        <p>Users' subscriptions will be considered cancelled as of the date they submit their cancellation request.
                                                            Any charges incurred after the cancellation date will be prorated based on the usage period.</p>
                                                        <p><strong class="privacy-policy-tutorial">Immediate Access Termination:</strong></p>
                                                        <p>Upon cancellation, users will lose access to the service and any associated features immediately or at the end of their current billing period, depending on your policy.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-4877142 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4a45f18" >
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-e0392fa shadepro-sticky-no elementor-widget elementor-widget-heading">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Refund Policy</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-365ff48 shadepro-sticky-no elementor-widget elementor-widget-text-editor" >
                                                    <div class="elementor-widget-container">
                                                        <p><strong class="privacy-policy-tutorial">Refund Eligibility:</strong></p>
                                                        <p>
                                                            Since payments are made after using the service, refunds are generally not provided for past usage.
                                                            Refunds may be considered in exceptional cases, such as technical issues preventing service access or billing errors.
                                                        </p>
                                                        <p><strong class="privacy-policy-tutorial">Satisfaction Guarantee / Trial Period:</strong></p>
                                                        <p>
                                                            we offer a satisfaction guarantee or trial period for new users, allowing them to try the service risk-free for a certain duration.
                                                            If users are dissatisfied during this period, they can cancel their subscription without incurring charges.
                                                        </p>
                                                        <p><strong class="privacy-policy-tutorial">Billing Disputes:</strong></p>
                                                        <p>Users who believe they've been billed incorrectly should contact customer support to resolve the issue.
                                                            Refunds for billing disputes will be assessed on a case-by-case basis, with appropriate documentation required for review.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/* <section
                                    class="elementor-section elementor-inner-section elementor-element elementor-element-0c3e98d elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                    data-id="0c3e98d" data-element_type="section"
                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div
                                            class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4ed18de"
                                            data-id="4ed18de" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    class="elementor-element elementor-element-390074f shadepro-sticky-no elementor-widget elementor-widget-shadepro-vertical-menu"
                                                    data-id="390074f" data-element_type="widget"
                                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                    data-widget_type="shadepro-vertical-menu.default">
                                                    <div class="elementor-widget-container">
                                                        <div class="shadepro-vertical-menu-wrap ">
                                                            <div class="shadepro-vertical-menu-container">
                                                                <ul id="shadepro-vertical-menu" class="shadepro-vertical-menu">
                                                                    <li id="menu-item-15585"
                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15585 "><a
                                                                            href="https://www.optimus-health.com/patient-administration/">Patient
                                                                            Administration</a></li>
                                                                    <li id="menu-item-15583"
                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15583 "><a
                                                                            href="https://www.optimus-health.com/electronic-health-record/">Electronic Health
                                                                            Record</a></li>
                                                                    <li id="menu-item-15584"
                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15584 "><a
                                                                            href="https://www.optimus-health.com/revenue-cycle-management/">Revenue Cycle
                                                                            Management</a></li>
                                                                    <li id="menu-item-15582"
                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15582 "><a
                                                                            href="https://www.optimus-health.com/pharmacy-management-system/">Pharmacy
                                                                            Management System</a></li>
                                                                    <li id="menu-item-15581"
                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15581 "><a
                                                                            href="https://www.optimus-health.com/lab-information-system/">Lab information
                                                                            system</a></li>
                                                                    <li id="menu-item-15580"
                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15580 "><a
                                                                            href="https://www.optimus-health.com/radiology-information-system/">Radiology
                                                                            information system</a></li>
                                                                    <li id="menu-item-15579"
                                                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-15579 "><a
                                                                            href="https://www.optimus-health.com/in-patient-modules/">In Patient</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
