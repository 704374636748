import React, { useEffect } from 'react'
import "../../assets/css/post-14890.css"

export default function Modules() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div data-elementor-type="wp-page" data-elementor-id="14890" class="elementor elementor-14890">
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-416e6eb elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
        data-id="416e6eb" data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6425988"
            data-id="6425988" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-b0aed72 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="b0aed72" data-element_type="section"
                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a5ee72e"
                    data-id="a5ee72e" data-element_type="column"
                    data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-dcaa159 shadepro-sticky-no elementor-widget elementor-widget-heading"
                        data-id="dcaa159" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">Our Modules
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-6f992b2 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                        data-id="6f992b2" data-element_type="widget"
                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="text-editor.default">
                        <div class="elementor-widget-container">
                          Optimus consists of various modules that let handle operations at every level.
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-2161a4a shadepro-sticky-no elementor-widget elementor-widget-shadepro-btn"
                        data-id="2161a4a" data-element_type="widget"
                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="shadepro-btn.default">
                        <div class="elementor-widget-container">
                          <div class="shadepro-btn-wrapper enable-icon-box-no">
                            <a class="shadepro-btn btn-type-boxed elementor-animation-float"
                              href="https://www.optimus-health.com/modules/">


                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7ca95de"
                    data-id="7ca95de" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-b53900e shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                        data-id="b53900e" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="548" height="596"
                            src="/assets/images/module_intro.png"
                            class="attachment-large size-large" alt="" loading="lazy"

                            sizes="(max-width: 548px) 100vw, 548px" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-e20bba8 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
        data-id="e20bba8" data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-da49bd5"
            data-id="da49bd5" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-0fbffd1 elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="0fbffd1" data-element_type="section"
                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-bdb895d"
                    data-id="bdb895d" data-element_type="column"
                    data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-6ee7384 shadepro-sticky-no elementor-widget elementor-widget-heading"
                        data-id="6ee7384" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">EHR
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-b4a3493 elementor-icon-list--layout-traditional elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                        data-id="b4a3493" data-element_type="widget"
                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="icon-list.default">
                        <div class="elementor-widget-container">
                          <ul class="elementor-icon-list-items">
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Take quicker, more intelligent action with
                                critical, real-time patient information at the point of care.</span>
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Expedite clinical encounters with flexible charting
                                options, reusable order sets, favorites and configurable templates</span>
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Complete history of the patient with problem
                                documentation details and CPOE in one place.</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a2c1ea3"
                    data-id="a2c1ea3" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-f03d2cb shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="f03d2cb" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async"
                            src="/assets/images/module_1-p6wi2r1kz09lsw3sh14wgcssd0kguvhctrg7us9gjk.png"
                            title="module_1" alt="module_1" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-6cb6c3b elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="6cb6c3b" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="828" height="870"
                            src="/assets/images/module_1a.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 828px) 100vw, 828px" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-dfed5f3 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="dfed5f3" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="1127" height="725"
                            src="/assets/images/module_1b.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 1127px) 100vw, 1127px" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-7120ad4 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
        data-id="7120ad4" data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c43c4fc"
            data-id="c43c4fc" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-1cd9515 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="1cd9515" data-element_type="section"
                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-857a033"
                    data-id="857a033" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-139a964 shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="139a964" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async"
                            src="/assets/images/module_2-p6wi3as6yj0mkpb49ro2eptgu3v6cinpwh5exlg6ww.png"
                            title="module_2" alt="module_2" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-47d5b1a elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="47d5b1a" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="629" height="656"
                            src="/assets/images/module_2a.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 629px) 100vw, 629px" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-97389a9"
                    data-id="97389a9" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-86a09a1 shadepro-sticky-no elementor-widget elementor-widget-heading"
                        data-id="86a09a1" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">Nursing</h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-ac393d7 elementor-icon-list--layout-traditional elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                        data-id="ac393d7" data-element_type="widget"
                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="icon-list.default">
                        <div class="elementor-widget-container">
                          <ul class="elementor-icon-list-items">
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Nurses can perform their day-to-day activities from
                                a single window screen</span>
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">eMAR manages the process of medication starting
                                from accepting the medication to the administering to the patient.</span>
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Each order is tracked with the different status
                                such as; dispensed, due for administration, missed dose, delayed does, future dose
                                etc.</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-59c2ae2 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
        data-id="59c2ae2" data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9691a1b"
            data-id="9691a1b" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-c1dfd21 elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="c1dfd21" data-element_type="section"
                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7cb15e5"
                    data-id="7cb15e5" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-4d806da shadepro-sticky-no elementor-widget elementor-widget-heading"
                        data-id="4d806da" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">RCM
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-2aa8e53 elementor-icon-list--layout-traditional elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                        data-id="2aa8e53" data-element_type="widget"
                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="icon-list.default">
                        <div class="elementor-widget-container">
                          <ul class="elementor-icon-list-items">
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Offers revenue management of cash flow for both
                                patients and insurance payment.</span>
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Full control of the service prices and coverage at
                                the time of ordering which enables to reduce administrative burden and avoid income
                                loss.</span>
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Covers the full process from issuing the bill to
                                cash collection including claim resubmission</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1f0d7cf"
                    data-id="1f0d7cf" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-efab6db shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="efab6db" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async"
                            src="/assets/images/module_3-p6wi3n03fdhx885nmhmoc8kgxyn5egzevn4k75h0xs.png"
                            title="module_3" alt="module_3" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-7d794e8 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="7d794e8" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="1380" height="267"
                            src="/assets/images/module_3a.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 1380px) 100vw, 1380px" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-19fff59 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="19fff59" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="1380" height="267"
                            src="/assets/images/module_3b.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 1380px) 100vw, 1380px" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-386ca5c elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="386ca5c" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="1380" height="267"
                            src="/assets/images/module_3c.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 1380px) 100vw, 1380px" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-e56a0d1 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
        data-id="e56a0d1" data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6bde272"
            data-id="6bde272" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-81787e1 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="81787e1" data-element_type="section"
                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-38edf7a"
                    data-id="38edf7a" data-element_type="column"
                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-c49d7cf shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="c49d7cf" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async"
                            src="/assets/images/module_1-p6wi2r1kz091cari4ygfx8yrz649kzi689ydttqi9s.png"
                            title="module_1" alt="module_1" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-574cbd5 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="574cbd5" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="1200" height="1277"
                            src="/assets/images/module_4.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 1200px) 100vw, 1200px" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-f3b7add elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="f3b7add" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="563" height="588"
                            src="/assets/images/module_4a.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 563px) 100vw, 563px" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-763dcd5"
                    data-id="763dcd5" data-element_type="column"
                    data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-506d5b1 shadepro-sticky-no elementor-widget elementor-widget-heading"
                        data-id="506d5b1" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">Patient administration</h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-2de1761 elementor-icon-list--layout-traditional elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                        data-id="2de1761" data-element_type="widget"
                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="icon-list.default">
                        <div class="elementor-widget-container">
                          <ul class="elementor-icon-list-items">
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Easy process for appointment-scheduling and
                                temporary registration through the call centre or the front office.</span>
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Create patient visits with full control of medical
                                and financial billing information</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-eb59f1b elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
        data-id="eb59f1b" data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fe76ea7"
            data-id="fe76ea7" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-4f0002b elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="4f0002b" data-element_type="section"
                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7413af8"
                    data-id="7413af8" data-element_type="column"
                    data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-c5e6a3f shadepro-sticky-no elementor-widget elementor-widget-heading"
                        data-id="c5e6a3f" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">Pharmacy and inventory
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-1097634 elementor-icon-list--layout-traditional elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                        data-id="1097634" data-element_type="widget"
                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="icon-list.default">
                        <div class="elementor-widget-container">
                          <ul class="elementor-icon-list-items">
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Keep track of control drugs and medicine expiration
                                dates.</span>
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Easily manage the flow of pharmacy items and
                                medical consumables</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-cfaa223"
                    data-id="cfaa223" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-e82a882 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="e82a882" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="1256" height="267"
                            src="/assets/images/module_5.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 1256px) 100vw, 1256px" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-2f6309e elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="2f6309e" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="1256" height="267"
                            src="/assets/images/module_5.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 1256px) 100vw, 1256px" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-aba9a0d elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="aba9a0d" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="1256" height="267"
                            src="/assets/images/module_5.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 1256px) 100vw, 1256px" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-ebaf2ed shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="ebaf2ed" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async"
                            src="/assets/images/module_6-p6wi44v1185sw83fe4o4mi87ufqx6tz4om0yag7lds.png"
                            title="module_6" alt="module_6" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-b040795 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
        data-id="b040795" data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-577c31c"
            data-id="577c31c" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-9f9e16f elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="9f9e16f" data-element_type="section"
                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-02096b9"
                    data-id="02096b9" data-element_type="column"
                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-8ff5f02 shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="8ff5f02" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async"
                            src="/assets/images/module_6-p6wi44v1185sw83fe4o4mi87ufqx6tz4om0yag7lds.png"
                            title="module_6" alt="module_6" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-83ba9ce elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="83ba9ce" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="1380" height="267"
                            src="/assets/images/module_6a.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 1380px) 100vw, 1380px" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-e3d4f7e elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="e3d4f7e" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="1380" height="267"
                            src="/assets/images/module_6a.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 1380px) 100vw, 1380px" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-bebec50 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="bebec50" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="1380" height="267"
                            src="/assets/images/module_6b.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 1380px) 100vw, 1380px" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-b6ad3f7"
                    data-id="b6ad3f7" data-element_type="column"
                    data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-2ca2070 shadepro-sticky-no elementor-widget elementor-widget-heading"
                        data-id="2ca2070" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">CPOE</h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-4fb3efa elementor-icon-list--layout-traditional elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                        data-id="4fb3efa" data-element_type="widget"
                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="icon-list.default">
                        <div class="elementor-widget-container">
                          <ul class="elementor-icon-list-items">
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Easily Issue orders or order sets, reuse recent
                                orders or recurring orders.</span>
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Services can be scheduled for a location or
                                equipment and can be tracked to completion.</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-c8bb404 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
        data-id="c8bb404" data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9ba9459"
            data-id="9ba9459" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-6c40fc7 elementor-section-content-middle elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="6c40fc7" data-element_type="section"
                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-26ccc9a"
                    data-id="26ccc9a" data-element_type="column"
                    data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-2571f6d shadepro-sticky-no elementor-widget elementor-widget-heading"
                        data-id="2571f6d" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">In-patient modules</h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-8350070 elementor-icon-list--layout-traditional elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                        data-id="8350070" data-element_type="widget"
                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="icon-list.default">
                        <div class="elementor-widget-container">
                          <ul class="elementor-icon-list-items">
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Manage admission, discharge, and transfer status
                                for each hospitalization</span>
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Operation theatre management</span>
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Emergency Room and bed management</span>
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Manage ward duties and capacities</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9634866"
                    data-id="9634866" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-2dc332a shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="2dc332a" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async"
                            src="/assets/images/module_7-p6wi4dbkqqhdspr50qbrqy3d6wl843wpprwblxv1ts.png"
                            title="module_7" alt="module_7" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-591f256 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="591f256" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="1040" height="999"
                            src="/assets/images/module_7b.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 1040px) 100vw, 1040px" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-174a6b5 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="174a6b5" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="840" height="555"
                            src="/assets/images/module_7a.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 840px) 100vw, 840px" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-7af3aff elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
        data-id="7af3aff" data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;gradient&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ef84aa8"
            data-id="ef84aa8" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-e2dfa2e elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="e2dfa2e" data-element_type="section"
                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-01e1e01"
                    data-id="01e1e01" data-element_type="column"
                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-873d56c shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="873d56c" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async"
                            src="/assets/images/module_1-p6wi2r1kz09lsw3sh14wgcssd0kguvhctrg7us9gjk.png"
                            title="module_1" alt="module_1" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-aff5c19 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="aff5c19" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="738" height="774"
                            src="/assets/images/feature_6a.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 738px) 100vw, 738px" />
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-84febeb elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                        data-id="84febeb" data-element_type="widget"
                        data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="image.default">
                        <div class="elementor-widget-container">
                          <img decoding="async" width="738" height="774"
                            src="/assets/images/feature_6b.png"
                            class="attachment-full size-full" alt="" loading="lazy"

                            sizes="(max-width: 738px) 100vw, 738px" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-6bed9f5"
                    data-id="6bed9f5" data-element_type="column"
                    data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-8de4ed4 shadepro-sticky-no elementor-widget elementor-widget-heading"
                        data-id="8de4ed4" data-element_type="widget"
                        data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">Document Management</h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-29df359 elementor-icon-list--layout-traditional elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                        data-id="29df359" data-element_type="widget"
                        data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                        data-widget_type="icon-list.default">
                        <div class="elementor-widget-container">
                          <ul class="elementor-icon-list-items">
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Upload soft copies of medical documents such as
                                medical reports, images, and test results.</span>
                            </li>
                            <li class="elementor-icon-list-item">
                              <span class="elementor-icon-list-icon">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  width="35px" height="35px" viewBox="0 0 35 35">
                                  <title>Group 6</title>
                                  <desc>Created with Sketch.</desc>
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                      <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                        <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                        <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                          rx="8"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                              <span class="elementor-icon-list-text">Link personal documents to patients such as Patient
                                ID's, insurance cards &amp; claims, and consent forms.</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
