import React, { useEffect } from 'react'
import "../../assets/css/post-15554.css"
import ReachUsBtn from '../../components/ReachUsBtn'
import ScheduleDemoSection from '../../components/ScheduleDemoSection'

export default function PMS() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div data-elementor-type="wp-page" data-elementor-id="15554" class="elementor elementor-15554">
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-f2c9886 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                >
                    <div class="elementor-container elementor-column-gap-default">
                        <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-871b5fe"
                            data-id="871b5fe" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section
                                    class="elementor-section elementor-inner-section elementor-element elementor-element-e95b6a5 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                    data-id="e95b6a5" data-element_type="section"
                                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div
                                            class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4523e26"
                                            data-id="4523e26" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    class="elementor-element elementor-element-be55556 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                    data-id="be55556" data-element_type="widget"
                                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}" data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Pharmacy Management</h2>
                                                    </div>
                                                </div>
                                                <div
                                                    class="elementor-element elementor-element-253d43a shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                    data-id="253d43a" data-element_type="widget"
                                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                    data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        A complete Enterprise-level inventory management which covers the entire process end to end.
                                                        <br /><br />
                                                        Take control items which include multilevel approvals for the transfer, consumption and
                                                        dispensing of items. Manage expiry dates and batch numbers for pharmaceutical items. <br /><br />

                                                        Easily receive the prescriptions ordered by doctors or register external prescription at the
                                                        Point of Sale. <br /><br />

                                                        Easily process the insurance approvals upon plan coverage in one form.
                                                    </div>
                                                </div>
                                                <ReachUsBtn />

                                            </div>
                                        </div>
                                        <div
                                            class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4cf40a7"
                                            data-id="4cf40a7" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    class="elementor-element elementor-element-ff00424 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInDown"
                                                    data-id="ff00424" data-element_type="widget"
                                                    data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                    data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img decoding="async" width="1256" height="267"
                                                            src="/assets/images/module_5.png"
                                                            class="attachment-full size-full" alt="" loading="lazy"
                                                            sizes="(max-width: 1256px) 100vw, 1256px" />
                                                    </div>
                                                </div>
                                                <div
                                                    class="elementor-element elementor-element-063c916 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInDown"
                                                    data-id="063c916" data-element_type="widget"
                                                    data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                    data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img decoding="async" width="1256" height="267"
                                                            src="/assets/images/module_5.png"
                                                            class="attachment-full size-full" alt="" loading="lazy"
                                                            sizes="(max-width: 1256px) 100vw, 1256px" />
                                                    </div>
                                                </div>
                                                <div
                                                    class="elementor-element elementor-element-71361f1 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInDown"
                                                    data-id="71361f1" data-element_type="widget"
                                                    data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                    data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <img decoding="async" width="1256" height="267"
                                                            src="/assets/images/module_5.png"
                                                            class="attachment-full size-full" alt="" loading="lazy"
                                                            sizes="(max-width: 1256px) 100vw, 1256px" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-a308d5a elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                    data-id="a308d5a" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                    <div class="elementor-container elementor-column-gap-default">
                        <div
                            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-74d2deb"
                            data-id="74d2deb" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <section
                                    class="elementor-section elementor-inner-section elementor-element elementor-element-cd11e67 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                    data-id="cd11e67" data-element_type="section"
                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div
                                            class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-210fa6d"
                                            data-id="210fa6d" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    class="elementor-element elementor-element-07a08dd shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                    data-id="07a08dd" data-element_type="widget"
                                                    data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                    data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Inventory features </h2>
                                                    </div>
                                                </div>
                                                <div
                                                    class="elementor-element elementor-element-13eef26 elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                                    data-id="13eef26" data-element_type="widget"
                                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                    data-widget_type="icon-list.default">
                                                    <div class="elementor-widget-container">
                                                        <ul class="elementor-icon-list-items">
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Items detailed information including different item
                                                                    codes </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Customization of Unit of Measures for products
                                                                </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Barcode printing and reading </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Pharmaceutical products categories and links to
                                                                    generic medicines </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Hierarchy of inventory locations </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Approvals for accepting the incoming and
                                                                    transferred stocks </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Replenishment level to guarantee a minimum quantity
                                                                    per item per location </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Keeping track of product batch numbers and expiry
                                                                    dates </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Tracking the inventory value and COGS using FIFO
                                                                    method </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Achieving multiple Delivery Notes per Sales Order
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9990bd1"
                                            data-id="9990bd1" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    class="elementor-element elementor-element-389c456 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                    data-id="389c456" data-element_type="widget"
                                                    data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                    data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default">Accounts Payable Features </h2>
                                                    </div>
                                                </div>
                                                <div
                                                    class="elementor-element elementor-element-89159a5 elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                                    data-id="89159a5" data-element_type="widget"
                                                    data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                    data-widget_type="icon-list.default">
                                                    <div class="elementor-widget-container">
                                                        <ul class="elementor-icon-list-items">
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Purchase of services and inventory items </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Support of multicurrency for international
                                                                    suppliers </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Supplier account management </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Internal requisitions </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Setting credit limit for each supplier </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Tracking dues per supplier bills </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Approvals for each transaction type </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Adjustments of supplier account or bills </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Integration with Inventory Control for receiving
                                                                    the items </span>
                                                            </li>
                                                            <li class="elementor-icon-list-item">
                                                                <span class="elementor-icon-list-icon">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="35px" height="35px" viewBox="0 0 35 35">
                                                                        <title>Group 6</title>
                                                                        <desc>Created with Sketch.</desc>
                                                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                                <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                    <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                    <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                        rx="8"></rect>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> </span>
                                                                <span class="elementor-icon-list-text">Integration with Cash and Banking for payments and
                                                                    refund </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
                <ScheduleDemoSection />
            </div>
        </>
    )
}
