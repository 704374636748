import React, { useEffect } from 'react'
import "../assets/css/post-14994.css"

export default function Features() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div data-elementor-type="wp-page" data-elementor-id="14994" class="elementor elementor-14994">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-98ddb28 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-sticky-no"
                data-id="98ddb28" data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c2333ed"
                        data-id="c2333ed" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-a262aff elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="a262aff" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-8e7dd5d"
                                        data-id="8e7dd5d" data-element_type="column"
                                        data-settings="{&quot;animation&quot;:&quot;none&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-85f3d11 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="85f3d11" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Our Features

                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-cb5eea2 shadepro-sticky-no elementor-widget elementor-widget-text-editor"
                                                data-id="cb5eea2" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    Optimus is built around features that cater specifically to each role in the hospital </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-3da6574 shadepro-sticky-no elementor-widget elementor-widget-shadepro-btn"
                                                data-id="3da6574" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="shadepro-btn.default">
                                                <div class="elementor-widget-container">
                                                    <div class="shadepro-btn-wrapper enable-icon-box-no">
                                                        <a class="shadepro-btn btn-type-boxed elementor-animation-float"
                                                            href="https://www.optimus-health.com/contact/">
                                                            Learn More
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-0bc9e9f"
                                        data-id="0bc9e9f" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-c8994bf shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="c8994bf" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1017" height="764"
                                                        src="/assets/images/backg_pattern.svg"
                                                        class="attachment-2048x2048 size-2048x2048" alt="" loading="lazy" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-8e21815 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInDown"
                                                data-id="8e21815" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="342" height="390"
                                                        src="/assets/images/intro_d.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 342px) 100vw, 342px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-b2edd20 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInDown"
                                                data-id="b2edd20" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="612" height="657"
                                                        src="/assets/images/intro_c-1.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 612px) 100vw, 612px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-fe76eb5 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="fe76eb5" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2d3e1a1"
                        data-id="2d3e1a1" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-2cfcd9f elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="2cfcd9f" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-bf3d8d7"
                                        data-id="bf3d8d7" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-b39dbbf shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="b39dbbf" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">For Management</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-6b3c17c elementor-icon-list--layout-traditional elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                                data-id="6b3c17c" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <ul class="elementor-icon-list-items">
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">A Business Intelligence dashboard that provides
                                                                wide perspective of the hospital revenue and capacity usage.</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ed84f0f"
                                        data-id="ed84f0f" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-70c9584 shadepro-sticky-no elementor-widget elementor-widget-image animated fadeInUp"
                                                data-id="70c9584" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/feature_1-p6wor59mund5ja9c2ing6arq1g53q0t7h3djeccphc.png"
                                                        title="feature_1" alt="feature_1" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-22a58a9 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="22a58a9" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1380" height="267"
                                                        src="/assets/images/module_3c.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 1380px) 100vw, 1380px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-22cfbac elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="22cfbac" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1380" height="267"
                                                        src="/assets/images/module_3b.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 1380px) 100vw, 1380px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-be81637 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="be81637" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1380" height="267"
                                                        src="/assets/images/module_3a.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 1380px) 100vw, 1380px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-8b81148 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="8b81148" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f5ccab2"
                        data-id="f5ccab2" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-a2b6cdb elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="a2b6cdb" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-494e27f"
                                        data-id="494e27f" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-ee55828 shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="ee55828" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="456" height="594"
                                                        src="/assets/images/feature_backg.png"
                                                        class="attachment-large size-large" alt="" loading="lazy"
                                                        sizes="(max-width: 456px) 100vw, 456px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-cfad00c elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="cfad00c" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="984" height="314"
                                                        src="/assets/images/feature_2b.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 984px) 100vw, 984px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-529a3ce elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="529a3ce" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="984" height="314"
                                                        src="/assets/images/feature_2b.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 984px) 100vw, 984px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-db80219 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="db80219" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="807" height="768"
                                                        src="/assets/images/feature_2a.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 807px) 100vw, 807px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-322228c"
                                        data-id="322228c" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-103f832 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="103f832" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">For Doctors</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-597a7d4 elementor-icon-list--layout-traditional elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                                data-id="597a7d4" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <ul class="elementor-icon-list-items">
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Take quicker, more intelligent action with
                                                                critical, real-time patient information at the point of care.</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Expedite clinical encounters with flexible charting
                                                                options, reusable order sets, favorites and configurable templates.</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Complete history of the patient with problem
                                                                documentation details and CPOE in one place.</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Easily Issue orders or order sets, reuse recent
                                                                orders or recurring orders.</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Services can be scheduled for a location or
                                                                equipment and can be tracked to completion.</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-4d38cd9 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="4d38cd9" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a67aac7"
                        data-id="a67aac7" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-55bcbf7 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="55bcbf7" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-272caad"
                                        data-id="272caad" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-f99a2f6 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="f99a2f6" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">For Nurses
                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-dd67bb7 elementor-icon-list--layout-traditional elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                                data-id="dd67bb7" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <ul class="elementor-icon-list-items">
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Nurses can perform their day-to-day activities from
                                                                a single window screen without having to navigate through menus and screens.</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">All information can be shown on the patient level
                                                                or at the ward level for better tracking of the medicine administration.</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ddc3ca5"
                                        data-id="ddc3ca5" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-435dc99 shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="435dc99" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="456" height="594"
                                                        src="/assets/images/feature_backg.png"
                                                        class="attachment-large size-large" alt="" loading="lazy"
                                                        sizes="(max-width: 456px) 100vw, 456px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-46fdd99 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="46fdd99" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="807" height="723"
                                                        src="/assets/images/feature_3b.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 807px) 100vw, 807px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-9997576 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="9997576" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="807" height="732"
                                                        src="/assets/images/feature_3a.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 807px) 100vw, 807px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-0f663f4 elementor-hidden-desktop elementor-hidden-tablet elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="0f663f4" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9d4ed41"
                        data-id="9d4ed41" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-06f2a92 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="06f2a92" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-85edf1f"
                                        data-id="85edf1f" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-b5e2934 shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="b5e2934" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1024" height="706"
                                                        src="/assets/images/feature_4-1024x706.png"
                                                        class="attachment-large size-large" alt="" loading="lazy"
                                                        sizes="(max-width: 1024px) 100vw, 1024px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-d374776 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="d374776" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="609" height="638"
                                                        src="/assets/images/feature_4b.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 609px) 100vw, 609px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-82aeddc elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="82aeddc" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="734" height="774"
                                                        src="/assets/images/feature_4a.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 734px) 100vw, 734px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-71dedfd"
                                        data-id="71dedfd" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-a77cf49 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="a77cf49" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Patient administration</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-00d3827 elementor-icon-list--layout-traditional elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                                data-id="00d3827" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <ul class="elementor-icon-list-items">
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Fast and clear process for the administration of
                                                                patients</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Easy process for the call centre or the front
                                                                office for appointments scheduling and temporary registration for appointments</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Comprehensive registration of patients’
                                                                demographics, insurance and documents to keep everything together.</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-299254a elementor-hidden-desktop elementor-hidden-tablet elementor-hidden-phone elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="299254a" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f6f217f"
                        data-id="f6f217f" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-91ac969 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="91ac969" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-57d7883"
                                        data-id="57d7883" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-dc91087 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="dc91087" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">Billing and insurance
                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-7ac4429 elementor-icon-list--layout-traditional elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                                data-id="7ac4429" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <ul class="elementor-icon-list-items">
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">An air-tight process for the services billing and
                                                                claiming to guarantee highest revenue.</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Starts with ordering, which previews the insurance
                                                                coverage, controls approvals to claims and reclaims to reduce administrative burden and
                                                                avoid income loss.</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Life of billers is made easier with full encounter
                                                                information in one place with full control of diagnosis and billing code.</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Insurance TOB easy management of plans details of
                                                                coverage rate, co-payment, deductibles and preauthorization for each service.</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Keeps track of individual patient accounts for
                                                                payment advances or money due to insurance rejections.</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-53f10a6"
                                        data-id="53f10a6" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-7ab9df2 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="7ab9df2" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="953" height="1196"
                                                        src="/assets/images/feature_5c.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 953px) 100vw, 953px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-6d786ed elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="6d786ed" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1127" height="1391"
                                                        src="/assets/images/feature_5a.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 1127px) 100vw, 1127px" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-3d3130f shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="3d3130f" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="456" height="594"
                                                        src="/assets/images/feature_backg.png"
                                                        class="attachment-large size-large" alt="" loading="lazy"
                                                        sizes="(max-width: 456px) 100vw, 456px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-63f7f52 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                data-id="63f7f52" data-element_type="section" data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div
                        class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-105b9b7"
                        data-id="105b9b7" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-7e80c21 elementor-section-boxed elementor-section-height-default elementor-section-height-default shadepro-adv-gradient-no shadepro-sticky-no"
                                data-id="7e80c21" data-element_type="section"
                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d7581f2"
                                        data-id="d7581f2" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-5e375a9 shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="5e375a9" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async"
                                                        src="/assets/images/module_7-p6wi4dbkqqhdspr50qbrqy3d6wl843wpprwblxv1ts.png"
                                                        title="module_7" alt="module_7" />
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-7dd0c61 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial shadepro-sticky-no  elementor-widget elementor-widget-image"
                                                data-id="7dd0c61" data-element_type="widget"
                                                data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <img decoding="async" width="1029" height="822"
                                                        src="/assets/images/feature_7b.png"
                                                        class="attachment-full size-full" alt="" loading="lazy"
                                                        sizes="(max-width: 1029px) 100vw, 1029px" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-6b52660"
                                        data-id="6b52660" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                class="elementor-element elementor-element-9bb0c71 shadepro-sticky-no elementor-widget elementor-widget-heading"
                                                data-id="9bb0c71" data-element_type="widget"
                                                data-settings="{&quot;_animation&quot;:&quot;none&quot;,&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default">System administrator</h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-db3c305 elementor-icon-list--layout-traditional elementor-list-item-link-full_width shadepro-sticky-no elementor-widget elementor-widget-icon-list"
                                                data-id="db3c305" data-element_type="widget"
                                                data-settings="{&quot;shadepro_sticky&quot;:&quot;no&quot;}"
                                                data-widget_type="icon-list.default">
                                                <div class="elementor-widget-container">
                                                    <ul class="elementor-icon-list-items">
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Manage the system users and their permissions and
                                                                roles.</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">Track transactions and changes through the audit
                                                                log.</span>
                                                        </li>
                                                        <li class="elementor-icon-list-item">
                                                            <span class="elementor-icon-list-icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="35px" height="35px" viewBox="0 0 35 35">
                                                                    <title>Group 6</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <g id="Features" transform="translate(-134.000000, -1428.000000)" fill="#FE6C6C">
                                                                            <g id="Group-6" transform="translate(134.000000, 1428.000000)">
                                                                                <rect id="Rectangle" x="0" y="0" width="26" height="26" rx="8"></rect>
                                                                                <rect id="Rectangle" opacity="0.100000001" x="9" y="9" width="26" height="26"
                                                                                    rx="8"></rect>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> </span>
                                                            <span class="elementor-icon-list-text">dynmacially fit the master data to your hospital's
                                                                needs.</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
